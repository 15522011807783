import './App.css';
import React, {useEffect, Suspense} from "react";
import {BrowserRouter as Router} from "react-router-dom";

import {store, persistor} from "./store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import "i18n";
import { keepAlive} from "./actions/auth";
import NotificationSocket from "./hook/NotificationSocket";
import AppRouter from "./AppRouter";
import Loading from "./pages/GlobalManagement/routing/Loading";
const App = () => {
    useEffect(() => {
        const keepAliveInterval = setInterval(() => {
            store.getState().auth.token && store.dispatch(keepAlive());
        }, 5000);
        return () => clearInterval(keepAliveInterval);
    }, []);
  return (
      <Provider store={store}>
          <Suspense fallback={<Loading/>}>
            <PersistGate persistor={persistor}>
                <Router>
                    <AppRouter/>
                </Router>
            </PersistGate>
          </Suspense>
          <NotificationSocket/>
      </Provider>
  );
}

export default App;
