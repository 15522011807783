
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled, ThemeProvider } from "@mui/material/styles";
import { GetDetectBrowserInfo } from "constants/constants";
import { mdTheme } from './Theme';
import { connect } from "react-redux";
import {Avatar, CssBaseline, Drawer, IconButton, Tab, Tabs} from "@mui/material";
import {selectGame, selectItem, toggleLeftSideBar} from "../../../actions/common";
import React, {useEffect, useState} from "react";
import AlertSuccess from "../../../components/alert/AlertSuccess";
import AlertFail from "../../../components/alert/AlertFail";
import {makeStyles} from "@mui/styles";
import {getListNews} from "../../../actions/announcement/announcement";
import "../../../assets/color.css";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import UserNew from "../navBar/UserNew";
import ListItemsNew from "./ListItemsNew";
import user_logo from "../../../assets/logo.png";
import './layout.scss';
import ClearIcon from '@mui/icons-material/Clear';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "var(--Color)",
    height: '60px',
    boxShadow: 'unset',
    justifyContent: 'center'
}));
const useStyles = makeStyles({
    bar: {
        // position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: '0',
        backgroundColor: 'var(--color73)',
        color: 'white',
        fontFamily: 'Gelasio',
        fontSize: '14px'
    },
    bar_content: {
        color: 'var(--color16)',
        fontWeight: '700',
        display: 'block', /* Important to give the content a width */
        width: '100%',
        maxHeight: '25px',
        transform: 'translateX(100%)', /* Animation start out of the screen */
        /* Add the animation */
        animation: '$move 30s linear infinite',
        whiteSpace: 'nowrap',
        "&:hover": {
            animationPlayState: "paused"
        }
    },

    /* Create the animation */
    '@keyframes move': {
        'to': { transform: 'translateX(-100%) '}
    },
    drawerMenuIcon: {
        '--size': '1.6rem',
        width: 'var(--size)',
        height: 'var(--size)',
    },
    box: {
        height: "35px",
        marginBottom: "7px",
        alignItems: 'center',
        display: 'flex',
        paddingLeft: '10px',
        '&.drawer-menu-icon': {
            padding: '0.7rem',
            marginBottom: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all ease 325ms',
            willChange: 'opacity',

            '&:hover, &:active': {opacity: 0.8},
        },
    },
});
function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{pt: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const LayoutNew = (Component, pageLabel, hideLeftMenu, isBetting) => {
    const ComposedComponent = ({
                                   globalLanguage,
                                   toggleLeftSideBar, sideBarStatus, status, errorStatus,
                                   notifyContent,
                                   getListNews,
                                   selectedGame,
                                   selectGame,
                                   selectItem
                               }) => {
        const {isDesktop, isLaptop, isTablet} = GetDetectBrowserInfo();
        const classes = useStyles();
        const {t} = useTranslation()
        const [tab, setTab] = useState(0);
        const history = useHistory();
        const [isDrawerOpened, setIsDrawerOpened] = useState(false);

        useEffect(() => {
            pageLabel && selectItem(pageLabel);
        }, [pageLabel, selectItem]);

        const onToggleDrawerMenu = () => {
            setIsDrawerOpened((isOpen) => !isOpen);
        };

        useEffect(() => {
            getListNews()
        }, [])

        const handleChangeTab = (event, newValue) => {
            setTab(newValue);
        }

        const demos = {
            soundcloud:
                '<iframe width="100%" height="800px" scrolling="no" frameborder="no" allow="autoplay" src="https://kf53k50.oreo88.com/en/compact/sports"></iframe>',
            soundcloud2:
                '<iframe width="100%" height="800px" scrolling="no" frameborder="no" allow="autoplay" src="https://fv-kf53k50.oreo88.com/en/esports-hub/"></iframe>',
        };
        const Iframe = (props) => {
            return (
                <div
                    dangerouslySetInnerHTML={{__html: props.iframe ? props.iframe : ""}}
                />
            );
        }
        const handleChooseGame = (value) => {
            if (value === selectedGame) {
                return;
            }
            if(value === 'result') {
                let url = `/${selectedGame}/main/result`;
                history.push(url);
                return;
            }
            selectGame(value)
            let url = '/toto/betting/simple-bet'.replace(selectedGame, value);
            history.push(url);
        }
        return (
            <ThemeProvider theme={mdTheme(globalLanguage)}>
                <Box sx={{display: "flex", height: '100vh'}}>
                    <CssBaseline/>
                    <Grid container
                          sx={{
                              position: "fixed",
                              overflow: 'hidden',
                              height: isDesktop || isLaptop ? '60px' : '70px',
                              zIndex: '99',
                          }}
                    >
                        <Grid item xs={12}>
                            <AppBar position="absolute">
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sx={{backgroundColor: "var(--neutral-00-day)"}}>
                                        <Grid container justifyContent="flex-end" alignItems='center'>
                                            <Grid item xs={12}>
                                                <Box>
                                                    <UserNew hideLeftMenu={hideLeftMenu} onToggleDrawerMenu={onToggleDrawerMenu}/>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Drawer open={isDrawerOpened}

                                        className={'tesst-neweee'}
                                            transitionDuration={500}
                                            sx={{
                                                "& .MuiDrawer-paper": {
                                                    width: '80%'
                                                }
                                            }}
                                    >
                                        <Grid item
                                              className={'menu-header'}
                                        >

                                            <Button
                                                onClick={onToggleDrawerMenu}
                                                className={'selected'}>
                                                <Box >
                                                    <Avatar src={user_logo} alt="photoURL" sx={{
                                                        width: '40px',
                                                        height: '40px'
                                                    }}/>
                                                </Box>
                                            </Button>
                                            {/*<Button onClick={onToggleDrawerMenu}>closed</Button>*/}
                                            <IconButton onClick={onToggleDrawerMenu}>
                                                <ClearIcon/>
                                            </IconButton>

                                        </Grid>
                                        <ListItemsNew hideGame={true} toggleDrawer={isDrawerOpened} onToggleDrawerMenu={onToggleDrawerMenu}/>
                                    </Drawer>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <div className={classes.bar}>*/}
                                    {/*       <span className={classes.bar_content}>*/}
                                    {/*           {notifyContent}*/}
                                    {/*       </span>*/}
                                    {/*    </div>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </AppBar>
                        </Grid>
                    </Grid>
                    <Box sx={{background:'var(--neutral-100-day)', width: '100%', height: '100%'}}>
                        <TabPanel value={tab} index={0} >
                            <Grid container
                                  // p={(isDesktop || isLaptop || isTablet) ? '100px' : '0'}
                                  sx={{
                                      padding: (isDesktop || isLaptop || isTablet) ? '20px 4px 20px 20px' : '0',
                                      paddingTop: (isDesktop || isLaptop) ? '55px' : '50px',
                                  }}
                            >
                                { !hideLeftMenu && (isDesktop || isLaptop) &&
                                    <Grid item xs={(isDesktop) ? 1.7 : isLaptop? 2 : 12}>
                                        <Grid container
                                              sx={{
                                                  position: "fixed",
                                                  overflow: 'hidden',
                                                  maxWidth: '16%',
                                              }}
                                        >
                                            <Grid

                                                sx={{
                                                    width:' 100%',
                                                    backgroundColor: 'transparent'}}
                                            >
                                                <ListItemsNew toggleDrawer={sideBarStatus}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={hideLeftMenu ? 12 : (isDesktop) ? 10.3 : isLaptop ? 10 : 12}>
                                    <Box sx={{
                                        pl: sideBarStatus
                                            ? (
                                                isDesktop ? '0'
                                                    : isLaptop ? '0'
                                                        : isTablet ? '1.5rem'
                                                            : '1rem'
                                            )
                                            : (
                                                isDesktop ? '100px'
                                                    : isLaptop ? '60px'
                                                        : isTablet ? '1.5rem'
                                                            : '1rem'
                                            )
                                        ,
                                        pr: sideBarStatus
                                            ? (
                                                isDesktop ? '20px'
                                                    : isLaptop ? '0px'
                                                        : isTablet ? '1.5rem'
                                                            : '1rem'
                                            )
                                            : (
                                                isDesktop ? '100px'
                                                    : isLaptop ? '60px'
                                                        : isTablet ? '1.5rem'
                                                            : '1rem'
                                            )
                                        ,
                                        pb: isDesktop ? '50px'
                                            : isLaptop ? '50px'
                                                : isTablet ? '1.5rem'
                                                    : '1.5rem',
                                        minHeight: "70vh",
                                        overflow: 'auto'

                                    }}
                                    className={`content-container ${isBetting? 'betting-container' : ''}`}
                                    >
                                        <Component/>
                                    </Box>
                                    {status === true ? <AlertSuccess/> : null}
                                    {errorStatus === true ? <AlertFail/> : null}
                                </Grid>

                            </Grid>
                        </TabPanel>
                        <TabPanel value={tab} index={1} sx={{
                            ".MuiBox-root": {
                                paddingTop: "0px"
                            }
                        }}>
                            <Iframe iframe={demos["soundcloud"]} allow="autoplay" />
                        </TabPanel>
                        <TabPanel value={tab} index={2} >
                            <Iframe iframe={demos["soundcloud2"]} allow="autoplay" />
                        </TabPanel>
                    </Box>
                </Box>
            </ThemeProvider>
        );

    };
    const mapStateToProps = (state) => ({
        globalLanguage: state.language.language,
        sideBarStatus: state.common.lefSideBarControl,
        errorStatus: state.alert.errorStatus,
        status: state.alert.status,
        notifyContent: state.announcement.notifyContent,
        selectedGame: state.common.selectedGame,
    });
    return connect(mapStateToProps, {toggleLeftSideBar, getListNews, selectGame, selectItem})(ComposedComponent);
}

export default LayoutNew;