import {
    CREATE_SIMPLE_BET,
    CREATE_SIMPLE_BET_TOTO,
    GET_CREDIT_INFO, GET_DRAW_DATE,
    GET_DRAW_DAY
} from "../../actions/type";

const initialState = {
    listDrawDay: [],
    creditInfo: {},
    isCreateSimpleBet: false,
    isCreateSimpleBetToto: false,
    listDrawDate: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_DRAW_DAY:
            return {
                ...state,
                listDrawDay: payload
            }
        case CREATE_SIMPLE_BET:
            return {
                ...state,
                isCreateSimpleBet: !state.isCreateSimpleBet
            }
        case CREATE_SIMPLE_BET_TOTO:
            return {
                ...state,
                isCreateSimpleBetToto: !state.isCreateSimpleBetToto
            }
        case GET_CREDIT_INFO:
            return {
                ...state,
                creditInfo: payload
            }
        case GET_DRAW_DATE:
            return {
                ...state,
                listDrawDate: payload
            }
        case GET_DRAW_DAY:
            return {
                ...state,

            }
        default:
            return state;
    }
}
