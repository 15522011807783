import { connect } from "react-redux";
import {
    Box
} from "@mui/material";
import React, {Fragment, useEffect} from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import {GetDetectBrowserInfo, LIST_GAME_CODE} from "../../constants/constants";
import {selectGame} from "../../actions/common";
const SportGame = ({selectGame}) => {
    const { t } = useTranslation();
    const {isDesktop, isLaptop, isTablet} = GetDetectBrowserInfo();
    useEffect(() => {
        selectGame(LIST_GAME_CODE?.SPORT);
    }, []);
    const Iframe = (props) => {
        return (
            <div
                dangerouslySetInnerHTML={{__html: props.iframe ? props.iframe : ""}}
            />
        );
    }
    return (
        <Fragment>
            <Box px={isLaptop || isDesktop ? 2 : 0}>
                <Grid
                    container
                    spacing={1}
                    justifyContent="space-between"
                >
                    <Grid item xs={12}>
                        <Iframe iframe={'<iframe width="100%" height="800px" scrolling="no" frameBorder="no" allow="autoplay" src="https://kf53k50.oreo88.com/en/compact/sports"/>'} allow="autoplay" />
                    </Grid>
                </Grid>
            </Box>

        </Fragment>
    );
};

const mapStateToProps = (state) => ({

});
export default connect(mapStateToProps, {
    selectGame
})(SportGame);
