import {connect} from "react-redux";
import  {OutlinedInput, InputAdornment, IconButton} from "@mui/material";
import {selectItem} from "../../../actions/common";
import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {GetDetectBrowserInfo, LIST_MENU_CODE} from "../../../constants/constants";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import * as React from "react";
import {useOutlinedInputStyles} from "../../../components/input/useOutlinedInputStyles";
import {useOutlinedInputStylesError} from "../../../components/input/useOutlinedInputStylesError";
import AgreeButton from "../../../components/button/dialog/AgreeButton";
import {dialogStyles} from "../../../components/style/DialogStyle.styles";
import {changePassword} from "../../../actions/auth";
import {makeStyles} from "@mui/styles";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Box from "@mui/material/Box";
import {ItemPanelPaper} from "../../../components/PanalPaper/ItemPanelPaper";
const useStyles = makeStyles({
    formInput: {
        background: 'var(--neutral-300-day)',
        height: '40px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    formLabel: {
        whiteSpace: 'nowrap'
    }
});
const Password = ({selectItem, changePassword}) => {
    const {t} = useTranslation();
    const classes = dialogStyles();
    const classesCustom = useStyles();
    const { isDesktop, isLaptop, isTablet } = GetDetectBrowserInfo();
    const outlinedInputClasses = useOutlinedInputStyles();
    const outlinedInputStylesError = useOutlinedInputStylesError();
    const [msgError, setMsgError] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [objEdit, setObjEdit] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [showPass, setShowPass] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [error, setError] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    useEffect(() => {
        selectItem(LIST_MENU_CODE?.CHANGE_PASSWORD)
    }, []);

    const handleChange = (value, name) => {
        setObjEdit({...objEdit, [name]: value});
    }
    const handleOk = () => {

        setError({
            ...error,
            currentPassword: objEdit?.currentPassword === '' || objEdit?.currentPassword === undefined,
            newPassword: objEdit?.newPassword === '' || objEdit?.newPassword === undefined || objEdit?.currentPassword === objEdit?.newPassword,
            confirmPassword: objEdit?.confirmPassword === '' || objEdit?.confirmPassword === undefined || objEdit?.newPassword !== objEdit?.confirmPassword,
        })

        if(objEdit?.currentPassword === '' || objEdit?.newPassword === '' || objEdit?.newPassword === '') {
            setMsgError({
                currentPassword: t("requiredField"),
                newPassword: t("requiredField"),
                confirmPassword: t("requiredField")
            });
            setError({
                currentPassword: true,
                newPassword: true,
                confirmPassword: true,
            })
        } else if(objEdit?.currentPassword === objEdit?.newPassword){
            setMsgError({
                currentPassword: '',
                newPassword: t("Current password and new password should not match"),
                confirmPassword: ''
            });
        } else if(objEdit?.newPassword !== objEdit?.confirmPassword) {
            setMsgError({
                currentPassword: '',
                newPassword: '',
                confirmPassword: t("New password and confirm password doesn't match")
            });
        } else {
            setMsgError({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
            const dataSubmit = {
                "currentPassword": objEdit?.currentPassword,
                "newPassword": objEdit?.newPassword
            }
            changePassword(dataSubmit);
        }
    }
    const handleShowPass = (name, value) => {
        setShowPass({...showPass, [name]: value})
    }
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={(isLaptop || isDesktop) ? 8 : 12}>
                    <Box px={(isLaptop || isDesktop) ? 2 : 0}>
                        <Grid container
                              spacing={1}
                              justifyContent="space-between"
                              alignItems="center"
                        >
                            <Grid item xs={12}>
                                <ItemPanelPaper>
                                    <Grid container p={(isLaptop || isDesktop || isTablet) ? 4 : 2} spacing={2} display={'block'}>
                                        <Grid item sx={12}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    className={classes.formLabelContainer}
                                                    xs={(isDesktop || isLaptop || isTablet) ? 4 : 12}
                                                >
                                                    <Typography className={classesCustom.formLabel}>{t("Current Password")}</Typography>
                                                </Grid>

                                                <Grid item xs={(isDesktop || isLaptop || isTablet) ? 8 : 12}>
                                                    <FormControl fullWidth size="small">
                                                        <OutlinedInput
                                                            value={objEdit?.currentPassword || ""}
                                                            onChange={(e) => handleChange(e.target.value.trim(), "currentPassword")}
                                                            classes={error?.currentPassword ? outlinedInputStylesError : outlinedInputClasses}
                                                            placeholder={t("Type current password")}
                                                            inputProps={{ maxLength: 30}}
                                                            className={classesCustom.formInput}
                                                            type={showPass?.currentPassword ? 'text' : 'password'}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => handleShowPass('currentPassword', !showPass?.currentPassword)}
                                                                        edge="end"
                                                                        sx={{'& .MuiSvgIcon-root ': { color: 'var(--neutral-500-day)'}}}
                                                                    >
                                                                        {showPass?.currentPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                    <Typography className={classes.formLabelError}>{msgError?.currentPassword}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={12}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    className={classes.formLabelContainer}
                                                    xs={(isDesktop || isLaptop || isTablet) ? 4 : 12}
                                                >
                                                    <Typography className={classesCustom.formLabel}>{t("New Password")}</Typography>
                                                </Grid>

                                                <Grid item xs={(isDesktop || isLaptop || isTablet) ? 8 : 12}>
                                                    <FormControl fullWidth size="small">
                                                        <OutlinedInput
                                                            value={objEdit?.newPassword || ""}
                                                            onChange={(e) => handleChange(e.target.value.trim(), "newPassword")}
                                                            classes={error?.newPassword ? outlinedInputStylesError : outlinedInputClasses}
                                                            placeholder={t("Type a new password")}
                                                            inputProps={{ maxLength: 30}}
                                                            className={classesCustom.formInput}
                                                            type={showPass?.newPassword ? 'text' : 'password'}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => handleShowPass('newPassword', !showPass?.newPassword)}
                                                                        edge="end"
                                                                        sx={{'& .MuiSvgIcon-root ': { color: 'var(--neutral-500-day)'}}}
                                                                    >
                                                                        {showPass?.newPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                    <Typography className={classes.formLabelError}>{msgError?.newPassword}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={12}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    className={classes.formLabelContainer}
                                                    xs={(isDesktop || isLaptop || isTablet) ? 4 : 12}
                                                >
                                                    <Typography className={classesCustom.formLabel}>{t("Confirm Password")}</Typography>
                                                </Grid>

                                                <Grid item xs={(isDesktop || isLaptop || isTablet) ? 8 : 12}>
                                                    <FormControl fullWidth size="small">
                                                        <OutlinedInput
                                                            value={objEdit?.confirmPassword || ""}
                                                            onChange={(e) => handleChange(e.target.value.trim(), "confirmPassword")}
                                                            classes={error?.confirmPassword ? outlinedInputStylesError : outlinedInputClasses}
                                                            placeholder={t("Retype new password")}
                                                            inputProps={{ maxLength: 30}}
                                                            className={classesCustom.formInput}
                                                            type={showPass?.confirmPassword ? 'text' : 'password'}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => handleShowPass('confirmPassword', !showPass?.confirmPassword)}
                                                                        edge="end"
                                                                        sx={{'& .MuiSvgIcon-root ': { color: 'var(--neutral-500-day)'}}}
                                                                    >
                                                                        {showPass?.confirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                    <Typography className={classes.formLabelError}>{msgError?.confirmPassword}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}} pt={2}>
                                                    <AgreeButton
                                                        name={t('Submit')}
                                                        handleClick={handleOk}
                                                        customBtnClassName={'primary-btn'}
                                                        borderRadius={'5px'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ItemPanelPaper>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
};
const mapStateToProps = () => ({
});
export default connect(mapStateToProps, {selectItem, changePassword})(Password);