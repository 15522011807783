import React from "react";
import "../../assets/color.css";
export const renderValueSelect = (option, placeholderText) => {
    if (option === null || option === "") {
        return <span style={{color: 'var(--color43)', paddingLeft: '5px'}}>{placeholderText || ''}</span>;
    }

    return (
        <span style={{color: 'var(--color8)'}}>{option?.label}</span>
    );
}