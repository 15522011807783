import {
    DELETE_FIXED_BET,
    GET_LIST_FIXED_BET, SAVE_LIST_FIXED_BET,
    TURN_OFF_BET, TURN_ON_BET
} from "../../actions/type";

const initialState = {
    listFixedBet: [],
    isUpdate: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_FIXED_BET:
            return {
                ...state,
                listFixedBet: payload
            }
        case TURN_ON_BET:
        case TURN_OFF_BET:
        case SAVE_LIST_FIXED_BET:
        case DELETE_FIXED_BET:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        default:
            return state;
    }
}
