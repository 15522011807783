import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles, withStyles } from "@mui/styles";
import {GetDetectBrowserInfo, LIST_GAME_CODE} from 'constants/constants';
import React, {Fragment, useState} from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classNames from 'classnames';
import Language from "../../../components/menu/languages/Language";
import {Avatar, FormControlLabel, IconButton, Tab, Tabs, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";
import {changeLogoutStatus} from "../../../actions/auth";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Badge from "@mui/material/Badge";
import NotificationLayout from "../layout/NotificationCenter/NotificationLayout";
import "../../../assets/color.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from "@mui/material/styles";
import user_logo from '../../../assets/user2.png'
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Biểu tượng cho chế độ tối
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LogoutIcon from '@mui/icons-material/Logout';
import Switch from '@mui/material/Switch';
import './Style.css';
import Credit from "../../../components/menu/credit/Credit";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import MainListItems from "../layout/ListItems";
import AlertSuccess from "../../../components/alert/AlertSuccess";
import AlertFail from "../../../components/alert/AlertFail";
import icon_4D from "../../../assets/icon_4D.svg"
import icon_4D_selected from "../../../assets/icon_4D_selected.svg"
import icon_toto from "../../../assets/icon_toto.svg";
import icon_toto_selected from '../../../assets/icon_toto_selected.svg';
import icon_sport from "../../../assets/icon_sport.svg"
import icon_esport from "../../../assets/icon_esport.svg"
import './User.scss'
import active_status from "../../../assets/active_status.svg";
import in_active from "../../../assets/in_active.svg";
import suspended_status from "../../../assets/suspended_status.svg";
import blocked_status from "../../../assets/blocked_status.svg";
import logo_tmp from '../../../assets/logo.png';
import menu_mb from '../../../assets/menu_mb.svg';
import switch_mode from "../../../assets/switch_darkmode.svg"
import {selectGame} from "../../../actions/common";
const useStyles = makeStyles({
    box: {
        height: "60px",
        marginBottom: "7px",
        alignItems: 'center',
        display: 'flex',
        paddingLeft: '10px',
        '&.drawer-menu-icon': {
            padding: '0.8rem',
            marginBottom: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all ease 325ms',
            willChange: 'opacity',

            '&:hover, &:active': {opacity: 0.8},
        },
    },
    drawerMenuIcon: {
        '--size': '1.6rem',
        width: 'var(--size)',
        height: 'var(--size)',
    },
    // name: {
    //     color: "var(--color3)",
    // },
    displayText: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.05em !important',
        textTransform: 'initial'
    },
    button: {
        display: "flex",
        "&:hover": {
            backgroundColor: "var(--color80)",
        },
    },
    headerButton: {
        minWidth: "unset !important",
        padding: "0 !important"
    }
});

const StyledMenuItem = withStyles((theme) => ({
    root: {
        ...theme.typography.body2,
        "& .MuiListItemText-primary": {
            padding: theme.spacing(1),
            textAlign: 'center',
            border: 'var(--color1)'
        },
    },
}))(MenuItem);
//style menu
const StyledMenu = withStyles({
    paper: {
        border: "1px solid var(--color13)",
        marginTop: "0.8rem",
        width: "250px",
    },
})((props) => <Menu elevation={0}  {...props} />);

const UserNew = ({isLogin, user, changeLogoutStatus, hideLeftMenu, unRead, selectedGame, listLeftMenu, onToggleDrawerMenu, selectGame}) => {
    const history = useHistory();
    const classes = useStyles();
    const gameCodeList = [
        {gameCode: '4d', img: icon_4D, imgSelected: icon_4D_selected, text: '4D'},
        {gameCode: 'toto', img: icon_toto, imgSelected: icon_toto_selected, text: 'Toto'},
        {gameCode: 'sport', img: icon_sport, imgSelected: icon_4D_selected, text: 'Sport'},
        {gameCode: 'esport', img: icon_esport, imgSelected: icon_4D_selected, text: 'Esport'}
    ];
    const logOut = async () => {
        handleSignOut()
    };

    const handleSignOut = () => {
        changeLogoutStatus();
        return history.push("/login");
    };
    const {t} = useTranslation();

    const { isDesktop, isLaptop, isTablet } = GetDetectBrowserInfo();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElCredit, setAnchorElCredit] = React.useState(null);
    const [notifyElm, setNotifyElm] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickGame = (gameCode) => {
        let url = '';
        selectGame(gameCode)
        switch (gameCode) {
            case LIST_GAME_CODE.SG4D:
                url = `/4d/betting/simple-bet`;
                break;
            case LIST_GAME_CODE.TOTO:
                url = `/toto/betting/simple-bet`;
                break;
            case LIST_GAME_CODE.SPORT:
                url = `/game/sport`;
                break;
            case LIST_GAME_CODE.ESPORT:
                url = `/game/esport`;

                break;
        }
        history.push(url);
    }
    const handleClickMainMenu = () => {
        onToggleDrawerMenu()
    }
    const handleClickLogo = () => {
        history.push('/main');
    }
    const handleClickCredit = (event) => {
        setAnchorElCredit(event.currentTarget);
    };

    const onCloseNotification = () => {
        setNotifyElm(false);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseCredit = () => {
        setAnchorElCredit(null);
    };
    const handleGotoDownlineCredit = () => {
        let url = `/main/profile/current-downline-credit`;
        history.push(url);
    }
    const demos = {
        soundcloud:
            '<iframe width="100%" height="800px" scrolling="no" frameborder="no" allow="autoplay" src="https://kf53k50.oreo88.com/en/compact/sports"></iframe>',
        soundcloud2:
            '<iframe width="100%" height="800px" scrolling="no" frameborder="no" allow="autoplay" src="https://fv-kf53k50.oreo88.com/en/esports-hub/"></iframe>',
    };
    const Iframe = (props) => {
        return (
            <div
                dangerouslySetInnerHTML={{__html: props.iframe ? props.iframe : ""}}
            />
        );
    }
    return (
        <div>
            <Grid container
                  alignItems="center" >
                { !isLogin && (isDesktop || isLaptop || isTablet) &&
                    <>

                        <Grid
                            item
                            justifyContent={'center'}
                            alignItems={'center'}
                            xs={(isDesktop || isLaptop ) ? 7 : isTablet ? 6 : 3}
                            display={'flex'}
                        >
                            <Grid container spacing={1} className={'game-menu'}>
                                {gameCodeList?.map((item) => (
                                    <Grid item >
                                        <Button onClick={() => handleClickGame(item?.gameCode)} className={`game-item ${selectedGame === item?.gameCode ? 'selected' : ''}`}>
                                            <Box className={'text-header'}>
                                                <CardMedia
                                                    component={"img"}
                                                    image={selectedGame === item?.gameCode ? item?.imgSelected :  item?.img}
                                                    className={'game-icon'}
                                                />
                                                <Typography sx={{textTransform: 'initial'}}>{item?.text}</Typography>
                                            </Box>
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </>

                }
                {!(isDesktop || isLaptop || isTablet) &&
                    <Grid container className={'game-menu-mb'}>
                        <Grid item xs={1.7} className={'game-menu-row'}>
                            <Button onClick={() => handleClickMainMenu()} className={'game-item'}>
                                <Box className={'header-button'}>
                                    <CardMedia
                                        component={"img"}
                                        image={menu_mb}
                                        className={'game-icon'}
                                    />
                                </Box>
                            </Button>
                        </Grid>
                        <Grid item xs={1.7} className={'game-menu-row'}>
                            <Button onClick={() => handleClickLogo()} className={'game-item logo'}>
                                <Box className={'text-header'}>
                                    <CardMedia
                                        component={"img"}
                                        image={logo_tmp}
                                        className={'game-icon'}
                                    />
                                </Box>
                            </Button>
                        </Grid>
                        {gameCodeList?.map((item) => (
                            <Grid item xs={1.7} className={'game-menu-row'}>
                                <Button onClick={() => handleClickGame(item?.gameCode)}
                                        className={`game-item ${selectedGame === item?.gameCode ? 'selected' : ''}`}
                                >
                                    <Box className={'header-button'}>
                                        <CardMedia
                                            component={"img"}
                                            image={selectedGame === item?.gameCode ? item?.imgSelected :  item?.img}
                                            className={'game-icon'}
                                        />
                                    </Box>
                                </Button>
                            </Grid>
                        ))}
                        <Grid item xs={1.8}>
                            <Button onClick={handleClick}>
                                <Box className={'text-header'}>
                                    <StyledAccount sx={{padding: '0'}}>
                                        <Avatar src={user_logo} alt="photoURL"/>
                                    </StyledAccount>
                                </Box>
                            </Button>
                        </Grid>
                    </Grid>
                }
                {(isDesktop || isLaptop || isTablet) &&
                    <Grid item xs={(isDesktop || isLaptop )? 5 : isTablet ? 6 :9 }>
                        <Grid container alignItems="center" justifyContent={'flex-end'}
                              sx={{ "& .MuiGrid-root": {
                                      padding: '10px'
                                  }
                              }}
                        >
                            <Grid
                                item
                                textAlign={'center'}
                            >
                                <Box>
                                    <Language/>
                                </Box>
                            </Grid>
                            { !isLogin
                                && (
                                    <>
                                        <Grid item textAlign={"center"} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft : "30px", paddingRight : "30px"}}>
                                            <Button onClick={handleClickCredit} className={classNames(classes.button , classes.headerButton)}>
                                                <Box className={'text-header'} sx={{height: '25px', alignItems: 'center', display: 'flex'}}>
                                                    <Typography className={classes.displayText}>{t('Credit')}: {user?.agentCreditLimit}$</Typography> <ExpandMoreIcon/>
                                                </Box>
                                            </Button>
                                        </Grid>
                                    </>
                                )
                            }
                            {/* Start - Notification Badge */}
                            {/*<Box component='div' className={classes.notificationWrapper}>*/}
                            {/*    <IconButton onClick={onClickNotification} sx={{ color: 'var(--neutral-1000-day)' }}>*/}
                            {/*        <Badge id="notification-icon" color="error"*/}
                            {/*               variant={unRead ? 'dot' : ''}*/}
                            {/*               sx={{cursor: 'pointer'}}*/}
                            {/*        >*/}
                            {/*            <NotificationsNoneOutlinedIcon className={classes.actionIcon} />*/}
                            {/*        </Badge>*/}
                            {/*    </IconButton>*/}
                            {/*</Box>*/}
                            {/* End - Notification Badge */}
                            <Grid
                                item
                                textAlign={"center"}
                                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft : "30px", paddingRight : "30px"}}>
                                <CardMedia
                                    component="img"
                                    sx={{width: '44px', height: '24px', marginRight: '15px'}}
                                    image={switch_mode}
                                />
                                <Button onClick={handleClick} className={classNames(classes.button , classes.headerButton)}>
                                    <Box className={'text-header'} sx={{height: '25px', alignItems: 'center', display: 'flex'}}>
                                        <StyledAccount sx={{padding: '0'}}>
                                            <Avatar src={user_logo} alt="photoURL" sx={{
                                                width: '30px',
                                                height: '30px'
                                            }}/>
                                            <ExpandMoreIcon/>
                                        </StyledAccount>
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <StyledMenu
                id="customized-menu1"
                className={'dropdown-menu-credit'}
                anchorEl={anchorElCredit}
                keepMounted
                open={Boolean(anchorElCredit)}
                onClose={handleCloseCredit}
                anchorOrigin={{
                    vertical: 20,
                    horizontal: 30,
                }}
            >
                <Box>
                    <>
                        <StyledMenuItem className={'dropdown-menu-row'}>
                            <ListItemText className={'menu-item left'} primary={`${t('Credit')}:`}/>
                            <ListItemText className={'menu-item right'} primary={`${user?.agentCreditLimit}$`}/>
                        </StyledMenuItem>
                        <StyledMenuItem className={'dropdown-menu-row'}>
                            <ListItemText className={'menu-item left'} primary={`${t('Available')}:`}/>
                            <ListItemText className={'menu-item right'} primary={`${user?.creditAvaiable}$`}/>
                        </StyledMenuItem>
                        <StyledMenuItem className={'dropdown-menu-row'}>
                            <ListItemText className={'menu-item left'} primary={`${t('Balance')}:`}/>
                            <ListItemText className={'menu-item right'} primary={`${user?.creditUsed}$`}/>
                        </StyledMenuItem>
                        <StyledMenuItem className={'dropdown-menu-row'}>
                            <ListItemText className={'menu-item link'} onClick={() =>handleGotoDownlineCredit()} primary={`${t('Settlement Info')}`}/>
                        </StyledMenuItem>
                    </>
                </Box>
            </StyledMenu>
            {!isLogin &&
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 20,
                        horizontal: 30,
                        top: 0
                    }}
                >
                    <Box >
                        <StyledMenuItem sx={{justifyContent: 'space-between', borderBottom: '1px solid var(--color1)'}}>
                            <StyledAccount sx={{padding: '0'}}>
                                <Avatar src={user_logo} alt="photoURL" sx={{
                                    width: '30px',
                                    height: '30px'
                                }}/>
                            </StyledAccount>
                            <ListItemText primary={user?.username} sx={{letterSpacing: '0.05em', '.MuiTypography-root': {textAlign: 'left !important'}}}/>
                        </StyledMenuItem>
                        {
                            listLeftMenu?.length> 0 && listLeftMenu?.filter(s => s?.menuCode === 'MAIN')?.[0]?.subMenu?.map((item) => (
                                <StyledMenuItem sx={{justifyContent: 'space-between'}} component={Link} to={item?.subMenuCode==='PROFILE'? item?.subMenuLink : `/${selectedGame|| '4d'}${item?.subMenuLink}`}>
                                    {item?.subMenuCode==='PROFILE' && <AccountCircleIcon/>}
                                    {item?.subMenuCode==="RESULT" && <CalendarMonthIcon/>}
                                    {item?.subMenuCode==="ANNOUNCEMENTS" && <FavoriteIcon/>}
                                    <ListItemText primary={t(item?.subMenuName)} sx={{letterSpacing: '0.05em', '.MuiTypography-root': {textAlign: 'left !important'}}}/>
                                </StyledMenuItem>
                            ))
                        }
                    </Box>
                    <StyledMenuItem onClick={() => logOut()} sx={{letterSpacing: '0.05em', '.MuiTypography-root': {textAlign: 'left !important'}}}>
                        <LogoutIcon/> <ListItemText primary={t("Log out")}/>
                    </StyledMenuItem>
                </StyledMenu>
            }
            <StyledMenu
                id="customized-menu"
                anchorEl={notifyElm}
                keepMounted
                open={Boolean(notifyElm)}
                onClose={onCloseNotification}
                aria-labelledby="composition-button"
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: '500px !important',
                        top: '65px !important',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        borderRadius: '15px',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 145,
                            width: 20,
                            height: 20,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 165, vertical: -200}}
            >
                <NotificationLayout></NotificationLayout>
            </StyledMenu>
        </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.user,
    unRead: state.notification.unRead,
    selectedGame: state.common.selectedGame,
    listLeftMenu: state.auth.listLeftMenu
});

export default connect(mapStateToProps, {changeLogoutStatus, selectGame})(UserNew);

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 2.5),
    borderRadius: '50%',
    backgroundColor: 'transparent',
}));