import {Paper} from "@mui/material";
import {styled} from "@mui/material/styles";
import "../../assets/color.css";
export const ItemPanelPaper = styled(Paper, { shouldForwardProp: (prop) => prop !== "padding"})(({theme, padding}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(padding),
    textAlign: 'center',
    color: 'var(--color26)',
    borderRadius: '3px',
    boxShadow: 'none',
}));