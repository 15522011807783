import {makeStyles} from "@mui/styles";
import "../../assets/color.css";
export const useOutlinedInputStylesError = makeStyles(() => ({
    root: {
        "& $notchedOutline": {
            borderColor: "var(--color21)"
        },
        "&:hover $notchedOutline": {
            borderColor: "var(--color21)"
        },
        "&$focused $notchedOutline": {
            borderColor: "var(--color21)"
        }
    },
    focused: {},
    notchedOutline: {}
}));
