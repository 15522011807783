import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles, withStyles } from "@mui/styles";
import { GetDetectBrowserInfo } from 'constants/constants';
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classNames from 'classnames';
import Language from "../../../components/menu/languages/Language";
import {IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import {changeLogoutStatus} from "../../../actions/auth";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Badge from "@mui/material/Badge";
import NotificationLayout from "../layout/NotificationCenter/NotificationLayout";
import "../../../assets/color.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles({
    box: {
        height: "60px",
        marginBottom: "7px",
        alignItems: 'center',
        display: 'flex',
        paddingLeft: '10px',
        '&.drawer-menu-icon': {
            padding: '0.8rem',
            marginBottom: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all ease 325ms',
            willChange: 'opacity',

            '&:hover, &:active': {opacity: 0.8},
        },
    },
    drawerMenuIcon: {
        '--size': '1.6rem',
        width: 'var(--size)',
        height: 'var(--size)',
    },
    name: {
        color: "var(--color3)",
    },
    displayText: {
        fontSize: '13px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.05em !important',
    },
    button: {
        display: "flex",
        "&:hover": {
            backgroundColor: "var(--color80)",
        },
    },
    headerButton: {
        minWidth: "unset !important",
        padding: "0 !important"
    }
});

const StyledMenuItem = withStyles((theme) => ({
    root: {
        ...theme.typography.body2,
        "& .MuiListItemText-primary": {
            padding: theme.spacing(1),
            textAlign: 'center',
            border: 'var(--color1)'
        },
    },
}))(MenuItem);
//style menu
const StyledMenu = withStyles({
    paper: {
        border: "1px solid var(--color13)",
        marginTop: "0.8rem",
        width: "250px",
    },
})((props) => <Menu elevation={0}  {...props} />);

const User = ({isLogin, user, changeLogoutStatus, hideLeftMenu, unRead, selectedGame, listLeftMenu}) => {
    const history = useHistory();
    const classes = useStyles();
    const logOut = async () => {
        handleSignOut()
    };

    const handleSignOut = () => {
        changeLogoutStatus();
        return history.push("/login");
    };
    const {t} = useTranslation();

    const { isDesktop, isLaptop, isTablet } = GetDetectBrowserInfo();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElCredit, setAnchorElCredit] = React.useState(null);
    const [notifyElm, setNotifyElm] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickCredit = (event) => {
        setAnchorElCredit(event.currentTarget);
    };

    const onCloseNotification = () => {
        setNotifyElm(false);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseCredit = () => {
        setAnchorElCredit(null);
    };
    const onClickNotification = (event) => {
        // readNotification()
        setNotifyElm(event?.currentTarget)
    };
    const handleGotoDownlineCredit = () => {
        let url = `/main/profile/current-downline-credit`;
        history.push(url);
    }
    return (
        <div>
            <Grid container
                  alignItems="center" >
                { !isLogin && !(isDesktop || isLaptop) && !hideLeftMenu &&
                    <Grid
                        item
                        justifyContent={'center'}
                        alignItems={'center'}
                        xs={(isTablet) ? 5: 3}
                    />
                }
                <Grid item xs={hideLeftMenu ? 12 : (isDesktop || isLaptop)? 12 : isTablet ? 6 : 9 }>
                    <Grid container alignItems="center" justifyContent={'flex-end'}
                        sx={{
                            "& .MuiGrid-root": {
                                padding: '10px'
                            }
                        }}
                    >
                        <Grid
                            item
                            textAlign={'center'}
                        >
                            <Box>
                                <Language/>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            textAlign={"center"}
                            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft : "30px", paddingRight : "30px"}}>
                            <Button onClick={handleClick} className={classNames(classes.button , classes.headerButton)}>
                                <Box className={classes.name} sx={{height: '25px', alignItems: 'center', display: 'flex'}}>
                                    <Typography className={classes.displayText}> {user?.username} </Typography><ExpandMoreIcon/>
                                </Box>
                            </Button>
                        </Grid>

                        { !isLogin &&
                            (isDesktop || isLaptop)
                            && (
                                <>
                                    <Grid item textAlign={"center"} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft : "30px", paddingRight : "30px"}}>
                                        <Button onClick={handleClickCredit} className={classNames(classes.button , classes.headerButton)}>
                                            <Box className={classes.name} sx={{height: '25px', alignItems: 'center', display: 'flex'}}>
                                                <Typography className={classes.displayText}>{t('Credit')}: {user?.agentCreditLimit}$</Typography> <ExpandMoreIcon/>
                                            </Box>
                                        </Button>
                                    </Grid>
                                </>
                            )
                        }
                        {/* Start - Notification Badge */}
                        <Box component='div' className={classes.notificationWrapper}>
                            <IconButton onClick={onClickNotification} sx={{ color: 'var(--color3)' }}>
                                <Badge id="notification-icon" color="error"
                                       variant={unRead ? 'dot' : ''}
                                       sx={{cursor: 'pointer'}}
                                >
                                    <NotificationsNoneOutlinedIcon className={classes.actionIcon} />
                                </Badge>
                            </IconButton>
                        </Box>
                        {/* End - Notification Badge */}
                    </Grid>
                </Grid>

            </Grid>


                <StyledMenu
                    id="customized-menu1"
                    anchorEl={anchorElCredit}
                    keepMounted
                    open={Boolean(anchorElCredit)}
                    onClose={handleCloseCredit}
                    anchorOrigin={{
                        vertical: 20,
                        horizontal: 30,
                    }}
                >
                    <Box>
                            <>
                                <StyledMenuItem>
                                    <ListItemText primary={`${t('Credit')}: ${user?.agentCreditLimit}$`} sx={{letterSpacing: '0.05em', fontSize: '9px !important'}}/>
                                </StyledMenuItem>
                                <StyledMenuItem>
                                    <ListItemText primary={`${t('Available')}: ${user?.creditAvaiable}$`} sx={{letterSpacing: '0.05em', color: 'var(--color81)', fontSize: '9px !important'}}/>
                                </StyledMenuItem>
                                <StyledMenuItem>
                                    <ListItemText primary={`${t('Balance')}: ${user?.creditUsed}$`} sx={{letterSpacing: '0.05em', color: 'var(--color82)', fontSize: '9px !important'}}/>
                                </StyledMenuItem>
                                <StyledMenuItem>
                                    <ListItemText onClick={() =>handleGotoDownlineCredit()} primary={`${t('Settlement Info')}`} sx={{letterSpacing: '0.05em', color: 'var(--color90)', textDecoration: 'underline', cursor: 'pointer', fontSize: '9px !important'}}/>
                                </StyledMenuItem>
                            </>
                    </Box>
                </StyledMenu>
            {!isLogin &&
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 20,
                        horizontal: 30,
                    }}
                >
                    <Box borderBottom={1} borderColor={'var(--color1)'}>
                        {!(isDesktop || isLaptop) &&
                            <>
                                <StyledMenuItem>
                                    <ListItemText primary={`${t('Credit')}: ${user?.agentCreditLimit}$`} sx={{letterSpacing: '0.05em', fontSize: '9px !important'}}/>
                                </StyledMenuItem>
                                <StyledMenuItem>
                                    <ListItemText primary={`${t('Available')}: ${user?.creditAvaiable}$`} sx={{letterSpacing: '0.05em', color: 'var(--color81)', fontSize: '9px !important'}}/>
                                </StyledMenuItem>
                                <StyledMenuItem>
                                    <ListItemText primary={`${t('Balance')}: ${user?.creditUsed}$`} sx={{letterSpacing: '0.05em', color: 'var(--color82)', fontSize: '9px !important'}}/>
                                </StyledMenuItem>
                                <StyledMenuItem>
                                    <ListItemText onClick={() =>handleGotoDownlineCredit()} primary={`${t('Settlement Info')}`} sx={{letterSpacing: '0.05em', color: 'var(--color90)', textDecoration: 'underline', cursor: 'pointer', fontSize: '9px !important'}}/>
                                </StyledMenuItem>
                            </>
                        }
                        {!hideLeftMenu &&
                            listLeftMenu?.length> 0 && listLeftMenu?.filter(s => s?.menuCode === 'MAIN')?.[0]?.subMenu?.map((item) => (
                                <StyledMenuItem component={Link} to={`/${selectedGame|| '4d'}${item?.subMenuLink}`}>
                                    <ListItemText primary={t(item?.subMenuName)} sx={{letterSpacing: '0.05em'}}/>
                                </StyledMenuItem>
                            ))
                        }
                    </Box>
                    <StyledMenuItem onClick={() => logOut()}>
                        <ListItemText sx={{color: 'var(--color83)'}} primary={t("Log out")}/>
                    </StyledMenuItem>
                </StyledMenu>
            }
            <StyledMenu
                id="customized-menu"
                anchorEl={notifyElm}
                keepMounted
                open={Boolean(notifyElm)}
                onClose={onCloseNotification}
                aria-labelledby="composition-button"
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: '500px !important',
                        top: '65px !important',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        borderRadius: '15px',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 145,
                            width: 20,
                            height: 20,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 165, vertical: -200}}
            >
                <NotificationLayout></NotificationLayout>
            </StyledMenu>
        </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.user,
    unRead: state.notification.unRead,
    selectedGame: state.common.selectedGame,
    listLeftMenu: state.auth.listLeftMenu
});

export default connect(mapStateToProps, {changeLogoutStatus})(User);