import {
    GET_LIST_CURRENT_DOWNLINE_CREDIT
} from "../../../actions/type";

const initialState = {
    listDownlineCredit: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_CURRENT_DOWNLINE_CREDIT:
            return {
                ...state,
                listDownlineCredit: payload
            }
        default:
            return state;
    }
}
