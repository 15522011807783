import {
    GET_LIST_FIGHTING_HISTORY
} from "../../../actions/type";

const initialState = {
    listFighting: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_FIGHTING_HISTORY:
            return {
                ...state,
                listFighting: payload
            }

        default:
            return state;
    }
}
