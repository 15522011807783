import React, {useState, useEffect, Fragment} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles, withStyles} from "@mui/styles";
import MuiListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {connect} from "react-redux";
import {selectGame, selectItem} from "actions/common";
import Grid from "@mui/material/Grid";
import {BREAK_POINT_FOR_LAPTOP} from "../../../constants/constants";
import Typography from "@mui/material/Typography";
import "../../../assets/color.css";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const useStyles = makeStyles({
    title2: {
        height: '20px',
        margin: "0 0 40px 16px !important",
        backgroundColor: 'transparent',
        padding: '0 16px !important',
        cursor: 'pointer'
    },
    mainMenu: {
        opacity: 0.8,
        fontSize: '14px',
        fontWeight: 'bolder',
        color: 'var(--color76)',
        paddingLeft: '20px',
        width: '90%'
    },
    closeTitle: {
        marginBottom: "2px",
        backgroundColor: 'var(--color3)',
        borderTopRightRadius: "30px",
        color: 'var(--color3)'
    },
    root: {
        padding: "40px",
        height: "500px",
    },
    paging: {
        "& .Mui-selected": {
            color: "var(--color2)!important",
            backgroundColor: "var(--color2)!important",
            minWidth: '1px',
            fontSize: '1px',
            height: '8px'
        },
        "& .MuiPaginationItem-root": {
            color: "var(--color1)",
            backgroundColor: "var(--color1)",
            minWidth: '1px',
            fontSize: '1px',
            height: '8px',
            margin: '10px'
        }
    },
    tableContainer: {
        "&.Mui-selected": {
            backgroundColor: "var(--color77)!important"
        },
        "&.MuiTableRow-hover": {
            "&:hover": {
                backgroundColor: "var(--color78)!important"
            }
        }
    },
    dialogCustomizedWidth: {
        maxWidth: "750px!important",
        overflow: "hidden",
        borderRadius: '20px!important',
        "& .MuiInputBase-root": {
            borderRadius: '40px',
            height: '40px',
            "& svg": {
                color: "var(--color18)",
            },
            "& fieldset": {
                borderColor: 'var(--color18)'
            }
        }
    },
    titleDialog: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    button: {
        borderRadius: '40px!important',
        backgroundColor: 'var(--color18)!important',
        height: "40px"
    },
    scrollBar: {
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            width: "5px",
            backgroundColor: "var(--color57)",
        },
        "&::-webkit-scrollbar": {
            borderRadius: "10px",
            width: "5px",
            backgroundColor: "var(--color58)",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            width: "5px",
            backgroundColor: "var(--color58)"
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 50px',
        backgroundAttachment: 'local, local, scroll, scroll',
        position: "relative" ,
        '@media screen and (min-width: 1024px) and (max-height: 900px)': {
            maxHeight: '90vh',
        },
    },
    menuItem: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: '16px !important',
        letterSpacing: '0.03em !important',
        color: 'var(--color7) !important',
    },
    menuItemHeading: {
        color: 'var(--color72) !important',
        fontSize: '12px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.05em !important',
        lineHeight: '14px !important',
    },
    menuItemDivide: {
        borderColor: 'var(--color15)!important',
    },
    gridMenuItem: {
        "&.MuiButtonBase-root": {
            color: 'var(--color84)',
            backgroundColor: 'var(--color102)',
            borderRadius: '3px',
            width:'100%',
            height:'30px',
            fontWeight:'600',
            fontSize:'12px',
            "&:hover": {
                backgroundColor: "#BDBBAD",
                color: "#1C1B1B",
            }
        }
    },
    gridMenuItemSelected: {
        "&.MuiButtonBase-root": {
            color: 'var(--color105)',
            backgroundColor: 'var(--color3)',
            border: '1px solid var(--color105)',
            borderRadius: '3px',
            width:'100%',
            height:'30px',
            fontWeight:'700',
            fontSize:'12px',
            "&:hover": {
                backgroundColor: "#BDBBAD",
                color: "#1C1B1B",
            }
        }
    },
});

const ListItem = withStyles({
    root: {
        "&.Mui-selected": {
            // backgroundColor: "#fff !important",
            width: "100%",
            color: '#D4AF37!important',

            "& .MuiTypography-root": {
                fontWeight: '700 !important',
            }
        },
        "&$selected": {},
        "&$selected:hover": {
            backgroundColor: "var(--color79)",
        },
    },
    selected: {},
})(MuiListItem);

const MainListItems = ({   user,
                           selectItem,
                           selectedItem,
                           toggleDrawer,
                           selectedGame,
                           listLeftMenu,
                           selectGame,
                           hideGame
                       }) => {
    const {t} = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [width,setWidth] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState({
        BETTING: true,
        REPORT: true,
        ACCOUNT: true,
        VIEW_LOGS: true
    });
    const location = useLocation();

    useEffect(() => {
        setSelectedIndex(selectedItem);
    }, [selectedItem]);

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    //handle search
    const handleListItemClick = (name, value) => {
        setIsOpen({
            ...isOpen,
            [name]: value
        })
    };
    const handleChooseGame = (value) => {
        if (value === selectedGame) {
            return;
        }
        selectGame(value);
        let url = '/toto/betting/simple-bet'.replace(selectedGame, value);
        history.push(url);
    }
    const handleGotoResult = () => {
        let url = `/${selectedGame}/main/result`;
        history.push(url);
    }
    const handleGotoDownlineCredit = () => {
        let url = `/main/profile/current-downline-credit`;
        history.push(url);
    }
    return (
        <div>
            {toggleDrawer && (
                <div>
                    <Fragment>
                        {!hideGame &&
                            <>
                                <Grid container sx={{paddingBottom:'1px'}}>
                                    <Grid item xs={6} sx={{padding:'2px'}}>
                                        <Button className={selectedGame === '4d' ? classes.gridMenuItemSelected : classes.gridMenuItem} onClick={() => handleChooseGame('4d')}>4D</Button>
                                    </Grid>
                                    <Grid item xs={6} sx={{padding:'2px'}}>
                                        <Button className={selectedGame === 'toto' ? classes.gridMenuItemSelected : classes.gridMenuItem} onClick={() => handleChooseGame('toto')}>TOTO</Button>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{paddingBottom:'1px'}}>
                                    <Grid item xs={12} sx={{padding:'2px'}}>
                                        <Button className={ `/${selectedGame}/main/result` === location.pathname? classes.gridMenuItemSelected : classes.gridMenuItem}
                                                onClick={() => handleGotoResult()}
                                        >{t('Result & Payout')}</Button>
                                    </Grid>
                                </Grid>
                                {/*<Grid container sx={{paddingBottom:'1px'}}>*/}
                                {/*    <Grid item xs={12} sx={{padding:'2px'}}>*/}
                                {/*        <Button className={ `/main/profile/current-downline-credit` === location.pathname? classes.gridMenuItemSelected : classes.gridMenuItem}*/}
                                {/*                onClick={() => handleGotoDownlineCredit()}*/}
                                {/*        >{t('Settlement Info')}</Button>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                            </>
                        }
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                {...width > BREAK_POINT_FOR_LAPTOP ? {height: '700px'} : {height: '75vh'}}
                                sx={{ overflow:'auto', width: '240px'}}
                                className={classes.scrollBar}
                            >
                                {listLeftMenu?.length> 0 && listLeftMenu?.filter(s => s?.menuCode !== 'MAIN')?.map((item) => (
                                    <>

                                        <ListItem
                                            button
                                            component={Link}
                                            to={`#`}
                                            onClick={() => handleListItemClick(item?.menuCode, !isOpen?.[item?.menuCode])}
                                            sx={{width: '100%', backgroundColor: '#e3e3e3', padding: '5px !important', display: 'flex', borderTop: '1px solid #fff'}}
                                        >
                                            <Typography variant="subtitle1" className={classes.mainMenu}>{t(item?.menuName)}</Typography>
                                            {isOpen?.[item?.menuCode] ? <ExpandMoreIcon/> : <ExpandLessIcon/>}

                                        </ListItem>
                                        {
                                            isOpen?.[item?.menuCode] && item?.subMenu?.map((sub) =>
                                                sub.subMenuCode === 'TICKET_FIGHTING' && user?.level === 'AG' ? null :
                                                    (
                                                        <ListItem
                                                            button
                                                            component={Link}
                                                            to={`/${selectedGame}${sub?.subMenuLink}`}
                                                            selected={location?.pathname === `/${selectedGame}${sub?.subMenuLink}`}
                                                            onClick={() => handleListItemClick( sub?.subMenuCode)}
                                                            sx={{padding: '3px', paddingLeft: '15px'}}
                                                        >
                                                            <ListItemText primary={
                                                                <Typography ml={2} variant='subtitle1' sx={{fontSize: '13px'}}>
                                                                    {t(sub?.subMenuName)}
                                                                </Typography>}/>
                                                        </ListItem>
                                                    )
                                            )
                                        }
                                        {/*{*/}
                                        {/*    isOpen?.[item?.menuCode] && item?.subMenu?.map((sub) => (*/}
                                        {/*        <ListItem*/}
                                        {/*            button*/}
                                        {/*            component={Link}*/}
                                        {/*            to={`/${selectedGame}${sub?.subMenuLink}`}*/}
                                        {/*            selected={location?.pathname === `/${selectedGame}${sub?.subMenuLink}`}*/}
                                        {/*            onClick={() => handleListItemClick( sub?.subMenuCode)}*/}
                                        {/*            sx={{padding: '3px', paddingLeft: '15px'}}*/}
                                        {/*        >*/}
                                        {/*            <ListItemText primary={*/}
                                        {/*                <Typography ml={2} variant='subtitle1' sx={{fontSize: '13px'}}>*/}
                                        {/*                    {t(sub?.subMenuName)}*/}
                                        {/*                </Typography>}/>*/}
                                        {/*        </ListItem>*/}
                                        {/*    ))*/}
                                        {/*}*/}
                                    </>
                                ))}

                            </Grid>
                        </Grid>

                    </Fragment>
                </div>
            )}
        </div>
    )
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    selectedItem: state.common.menuItemSelected,
    selectedGame: state.common.selectedGame,
    listLeftMenu: state.auth.listLeftMenu
});

export default connect(mapStateToProps, {
    selectItem,
    selectGame
})(MainListItems);