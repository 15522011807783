import {CREATE_ADDITIONAL_BET_TOTO, CREATE_MASS_BET, CREATE_MASS_BET_TOTO} from "../../actions/type";

const initialState = {
    isCreateMassbet: false,
    isCreateAdditionalToto: false,
    isCreateMassbetToto: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type } = action;
    switch (type) {
        case CREATE_MASS_BET:
            return {
                ...state,
                isCreateMassbet : !state.isCreateMassbet
            }
        case CREATE_ADDITIONAL_BET_TOTO:
            return {
                ...state,
                isCreateAdditionalToto : !state.isCreateAdditionalToto
            }
        case CREATE_MASS_BET_TOTO:
            return {
                ...state,
                isCreateMassbetToto: !state.isCreateMassbetToto
            }
        default:
            return state;
    }
}
