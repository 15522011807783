import {closeNotification, gotMessages, gotNotification, sendNotification} from "../actions/socket";
import React, {useCallback, useEffect} from 'react';
import useWebSocket from "react-use-websocket";
import {connect} from "react-redux";
import {getLocalToken} from "config/globalStatement";
import Grid from "@mui/material/Grid";
import NotificationFloatCard from "../pages/GlobalManagement/layout/NotificationCenter/NotificationFloatCard";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../assets/color.css";
const {REACT_APP_SOCKET_URL, REACT_API_LOCATION} = process.env;

const NotificationSocket = ({gotNotification, sendNotification, notificationConfig, messages, closeNotification, isAuthenticated, gotMessages}) => {

    const getSocketUrl = useCallback(() => {
        return new Promise((resolve) => {
            setTimeout(() => {
                if(isAuthenticated) {
                    resolve(`${REACT_API_LOCATION}?access_token=${getLocalToken()}`);
                }
            }, 2000);
        });
    }, [isAuthenticated]);
    const {
        lastMessage,
        lastJsonMessage
    } = useWebSocket(getSocketUrl, {
        shouldReconnect: (closeEvent) => true,
    });

    useEffect(() => {
        if (lastMessage && lastJsonMessage?.message !== 'closed') {
            gotMessages(JSON.parse(lastMessage.data));
        }
    }, [lastMessage])

    useEffect(() => {
        if (lastMessage && lastJsonMessage?.message !== 'closed') {
            gotNotification()
            if (validateConfig(lastJsonMessage?.notificationType) && isAuthenticated) {
                sendNotification({...JSON.parse(lastMessage.data), ['id']: JSON.parse(lastMessage.data).notificationTime + '-' + JSON.parse(lastMessage.data).function + '-' + JSON.parse(lastMessage.data).envName})
            }
        }
    }, [lastMessage]);

    const validateConfig = (notificationType) => {
        return !notificationConfig?.filter(s => s?.notificationType === notificationType)[0]?.disabled;
    }

    const unsubscribe = toast.onChange((payload) => {
        if(payload.status === 'removed') {
            closeNotification(payload.id)
        }
    })
    unsubscribe();

    useEffect(()=> {
        if(messages?.length > 0) {
            messages?.map(message => {
                toast(<NotificationFloatCard message={message}></NotificationFloatCard>, {
                    onClose: () => closeNotification(message.id),
                    type: message?.isSuccess ? 'success' : 'error',
                    toastId: message?.id,
                    icon: false,
                    closeButton: true,
                    transition: Zoom,
                    style: {
                        width: '500px',
                        borderRadius: '10px',
                        borderLeft: message?.isSuccess ? '10px solid var(--color53)' : '10px solid var(--color47)',
                    }
                });
            })
        }
    }, [messages])

    return (
        <div>
            <Grid>
                <ToastContainer
                    style={{width: '500px', right: '90px', top: '70px'}}
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                    theme="light"
                />
            </Grid>
        </div>
    )

}

const mapStateToProps = (state) => ({
    notificationConfig: state.notification.notificationConfig,
    messages: state.notification.messages,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {gotNotification, closeNotification, sendNotification, gotMessages})(NotificationSocket);