import {connect} from "react-redux";
import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {CardContent} from "@mui/material";
import {makeStyles, withStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {genInnerLink} from "../../../../utils/common";
import LinkText from "../../../../components/linkText/LinkText";
import {clickDeletedLink} from "../../../../actions/socket";
import "../../../../assets/color.css";
const StyledMenuItem = withStyles((theme) => ({
    root: {

    },
}))(MenuItem);

const useStyles = makeStyles(() => ({
}))
const NotificationFloatCard = (props, clickDeletedLink) => {
    const {t} = useTranslation();
    const {message} = props;
    const classes = useStyles();

    return (
        <Grid>
            <Grid sx={{maxHeight: '400px'}}>
                <Grid>
                    <StyledMenuItem button sx={{padding: 'unset'}} >
                        <CardContent sx={{width: '100%'}}>
                            <Grid item container xs={12} px={2}>
                                {
                                    message?.isSuccess ? (<Grid item xs={1}  display="flex" alignItems="center">
                                        <CheckCircleOutlineIcon sx={{width: '20px', height: '20px', color: 'var(--color53)'}}/>
                                    </Grid>) : (<Grid item xs={1}  display="flex" alignItems="center">
                                            <ErrorOutlineIcon sx={{width: '20px', height: '20px', color: 'var(--color47)'}}/>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={11} display="flex" alignItems="center">
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            lineHeight: '15px',
                                            color: message?.isSuccess ? 'var(--color53)' : 'var(--color47)',
                                            letterSpacing: '0.05em',
                                        }}>
                                        {t(message?.isSuccess ? 'Success' : 'Error')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} px={2}>
                                <Grid item xs={1}  display="flex" alignItems="center">
                                </Grid>
                                <Grid item xs={11} display="flex" alignItems="center">
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            color: 'var(--color7)',
                                            letterSpacing: '0.05em',
                                            marginTop: '0.6rem',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        <Trans t={t} i18nKey={message?.message}
                                               values={{ environment: message?.values?.environment, itemName: message?.values?.itemName, networkName: message?.values?.networkName, companyName: message?.values?.companyName, billingId: message?.values?.billingId, orderNo: message?.values?.orderNo, amount: message?.values?.amount, dueDate: message?.values?.dueDate}}
                                               components={{lnk: <LinkText href={genInnerLink(message)} item={message} clickDeletedLink={clickDeletedLink} />}}/>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </StyledMenuItem>
                </Grid>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {clickDeletedLink})(NotificationFloatCard);