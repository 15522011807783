import React, {lazy, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch, useLocation} from "react-router-dom";
import PrivateRoute from "./pages/GlobalManagement/routing/PrivateRoute";
import Layout from "./pages/GlobalManagement/layout/Layout";
import {getListMenu, loadUser} from "./actions/auth";
import LayoutNew from "./pages/GlobalManagement/layout/LayoutNew";
import Setting from "./pages/MainManagement/Profile/Setting";
import Password from "./pages/MainManagement/Profile/Password";
import SportGame from "./pages/Game/SportGame";
import EsportGame from "./pages/Game/EsportGame";

const Announcement = lazy(() => import('./pages/MainManagement/Announcement/Announcement'));
const Result = lazy(() => import('./pages/MainManagement/Result/Result'));
const NoMatch = lazy(() => import('./pages/GlobalManagement/routing/NoMatch'));
const Profile = lazy(() => import('./pages/MainManagement/Profile/MyInfomation'));
const MainPage = lazy(() => import('./pages/GlobalManagement/mainPage/MainPage'));
const SignInPage = lazy(() => import('./pages/GlobalManagement/auth/Login'));
const SavedBet = lazy(() => import('./pages/BettingManagement/SavedBet/SavedBet'));
const WildCardBet = lazy(() => import('./pages/BettingManagement/WildcardBet/WildCardBet'));
const MassBetTwo = lazy(() => import('./pages/BettingManagement/MassBetTwo/MassBetTwo'));
const MassBet = lazy(() => import('./pages/BettingManagement/MassBet/MassBet'));
const SimpleBet = lazy(() => import('./pages/BettingManagement/SimpleBet/SimpleBet'));
const LoginLog = lazy(() => import('./pages/LogManagement/LoginLog/LoginLog'));
const Statement = lazy(() => import('./pages/AccountManagement/Statement/Statement'));
const Transaction = lazy(() => import('./pages/LogManagement/Transaction/Transaction'));
const SystemLog = lazy(() => import('./pages/LogManagement/SystemLog/SystemLog'));
const OnlineList = lazy(() => import('./pages/AccountManagement/OnlineList/OnlineList'));
const Payment = lazy(() => import('./pages/AccountManagement/Payment/Payment'));
const Downline = lazy(() => import('./pages/AccountManagement/Downline/Downline'));
const SubAccount = lazy(() => import('./pages/AccountManagement/SubAccount/SubAccount'));
const FixedBet = lazy(() => import('./pages/BettingManagement/FixedBet/FixedBet'));
const BettingHistory = lazy(() => import('./pages/BettingManagement/BettingHistory/BettingHistory'));
const DownlineDetail = lazy(() => import('./pages/AccountManagement/Downline/DownlineDetail'));
const TelegramDetail = lazy(() => import('./pages/ReportManagement/TelegramDetail/TelegramDetail'));
// const DeletedDetail = lazy(() => import('./pages/ReportManagement/DeletedDetail/DeletedDetail'));
// const StrikeDetail = lazy(() => import('./pages/ReportManagement/StrikeDetail/StrikeDetail'));
// const BetDetail = lazy(() => import('./pages/ReportManagement/BetDetail/BetDetail'));
// const SummaryReport = lazy(() => import('./pages/ReportManagement/SummaryReport/SummaryReport'));
// const PlaceoutReport = lazy(() => import('./pages/ReportManagement/PlaceoutReport/PlaceoutReport'));
// const ReportFull = lazy(() => import('./pages/CompanyReport/StakeFilesInfo/StakeFilesInfo'));
// const StakeFilesInfo = lazy(() => import('./pages/CompanyReport/StakeFilesInfo/StakeFilesInfo'));
// const CurrentStakeInfo = lazy(() => import('./pages/CompanyReport/CurrentStakeInfo/CurrentStakeInfo'));
// const CompanyStrike = lazy(() => import('./pages/CompanyReport/CompanyStrike/CompanyStrike'));
// const CompanyProfitLoss = lazy(() => import('./pages/CompanyReport/CompanyProfitLoss/CompanyProfitLoss'));
// const CompanyPlaceout = lazy(() => import('./pages/CompanyReport/CompanyPlaceout/CompanyPlaceout'));
// const CompanyFullReport = lazy(() => import('./pages/CompanyReport/CompanyFullReport/CompanyFullReport'));
// const TelegramHelp = lazy(() => import('./pages/BettingManagement/TelegramHelp/TelegramHelp'));
// const UploadData = lazy(() => import('./pages/BettingManagement/UploadData/UploadData'));
// const FightingHistory = lazy(() => import('./pages/AccountManagement/FightingHistory/FightingHistory'))

const TotoDownline = lazy(() => import('./pages/Toto/AccountManagement/Downline/Downline'));
const TotoSubAccount = lazy(() => import('./pages/Toto/AccountManagement/SubAccount/SubAccount'));
const TotoDownlineDetail = lazy(() => import('./pages/Toto/AccountManagement/Downline/DownlineDetail'));
const TotoStatement = lazy(() => import('./pages/Toto/AccountManagement/Statement/Statement'));
const TotoPayment = lazy(() => import('./pages/Toto/AccountManagement/Payment/Payment'));
const TotoOnlineList = lazy(() => import('./pages/Toto/AccountManagement/OnlineList/OnlineList'));
// const TotoFightingHistory = lazy(() => import('./pages/Toto/AccountManagement/FightingHistory/FightingHistory'))
const TotoSimpleBet = lazy(() => import('./pages/Toto/BettingManagement/SimpleBet/SimpleBet'))
const TotoMassBet = lazy(() => import('./pages/Toto/BettingManagement/MassBet/MassBet'));
const TotoMassBetTwo = lazy(() => import('./pages/Toto/BettingManagement/MassBetTwo/MassBetTwo'));
const TotoBetHistory = lazy(() => import('./pages/Toto/BettingManagement/BettingHistory/BettingHistory'));
const TotoProfile = lazy(() => import('./pages/Toto/MainManagement/Profile/Profile'));
const TotoResult = lazy(() => import('./pages/Toto/MainManagement/Result/Result'));
const TotoAnnouncement = lazy(() => import('./pages/Toto/MainManagement/Announcement/Announcement'));
const TotoAdvanBet = lazy(() => import('./pages/Toto/BettingManagement/AdvanBet/AdvanBet'))
const TotoSavedBet = lazy(() => import('./pages/Toto/BettingManagement/SavedBet/SavedBet'))
const TotoFixedBet = lazy(() => import('./pages/Toto/BettingManagement/FixedBet/FixedBet'))
const CurrentDownlineCredit = lazy(() => import('./pages/AccountManagement/Downline/CurrentDownlineCredit'))
const Loading = lazy(() => import('./pages/GlobalManagement/routing/Loading'));
const Tnc = lazy(() => import('./pages/GlobalManagement/auth/tnc'));
const FullReport = lazy(() => import('./pages/Toto/ReportManagement/FullReport/FullReport'));
const FightingHistory = lazy(() => import('./pages/AccountManagement/FightingHistory/FightingHistory'))

const MenuComponentTotoMap = {
    PROFILE: TotoProfile,
    RESULT: TotoResult,
    ANNOUNCEMENTS: TotoAnnouncement,
    SIMPLE_BET: TotoSimpleBet,
    ADVAN_BET: TotoAdvanBet,
    ADDITIONAL_BET: TotoMassBetTwo,
    SAVED_BET: TotoSavedBet,
    FIXED_BET: TotoFixedBet,
    BETTING_HISTORY: TotoBetHistory,
    SUB_ACCOUNT: TotoSubAccount,
    DOWNLINE: TotoDownline,
    STATEMENT: TotoStatement,
    PAYMENT: TotoPayment,
    ONLINE_LIST:TotoOnlineList,
    LOGIN_LOGS:LoginLog,
    SYSTEM_LOGS: NoMatch,
    TRANSACTION_LOGS: NoMatch,
    MASS_BET: TotoMassBet,
    FULL_REPORT: FullReport,
    TICKET_FIGHTING: FightingHistory
};
const MenuComponent4DMap = {
    PROFILE: Profile,
    RESULT: Result,
    ANNOUNCEMENTS: Announcement,
    SIMPLE_BET: SimpleBet,
    WILDCARD_BET: WildCardBet,
    MASS_BET: MassBet,
    MASS_BET_2: MassBetTwo,
    LOGIN_LOGS: LoginLog,
    SAVED_BET: SavedBet,
    FIXED_BET: FixedBet,
    BETTING_HISTORY: BettingHistory,
    SUB_ACCOUNT: SubAccount,
    DOWNLINE: Downline,
    STATEMENT: Statement,
    PAYMENT: Payment,
    ONLINE_LIST: OnlineList,
    SYSTEM_LOGS: SystemLog,
    TRANSACTION_LOGS: Transaction,
    TELEGRAM_DETAIL: NoMatch,
    DELETED_DETAIL: NoMatch,
    FULL_REPORT: TelegramDetail,
    TICKET_FIGHTING: FightingHistory
};

const AppRouter = () => {

    const token = useSelector(state => state.auth?.token);
    const tncAgreed = useSelector(state => state.auth?.user?.tncAgreed);
    const leftMenuList = useSelector(state => state.auth?.listLeftMenu);
    const user = useSelector(state => state.auth?.user);

    const dispatch = useDispatch();
    const location = useLocation();
    const gameCode = location?.pathname?.split('/')?.[1];
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (token) {
            dispatch(loadUser());
            dispatch(getListMenu(gameCode !== '4d' && gameCode !== 'toto' ? '4d' : gameCode));
        } else if (!window.location.href.includes('/login')) {
            window.location.href = '/login';
            setLoading(false);
        }
    }, [token, gameCode]);

    useEffect(() => {
        if (token) {
            if (tncAgreed && window.location.pathname === '/') {
                window.location.href = '/main';
            }
            if (!tncAgreed && !window.location.href.includes('/tnc')) {
                window.location.href = '/tnc';
            }
        }
    }, [token, tncAgreed]);

    const memoizedArrayMenu = useMemo(() => {
        let newArr = [];
        const gameMenu = gameCode === '4d' ? MenuComponent4DMap: MenuComponentTotoMap;
        leftMenuList.map((leftMenu, index) => {
            const menuCode = leftMenu.menuCode;
            const MenuLayout = gameMenu[menuCode];
            const subMenu = leftMenu.subMenu;
            const isBetting = menuCode === 'BETTING';
            if(MenuLayout) {
                newArr =[...newArr, {key: `${menuCode}_${index}`,path: `/${gameCode}${leftMenu.menuLink}`, name: leftMenu.menuName, MenuLayout: MenuLayout, isBetting: isBetting}];

            }
            subMenu.map(sub => {
                if ((sub?.subMenuCode === 'TICKET_FIGHTING' && user?.level !== 'AG') || (sub?.subMenuCode !== 'TICKET_FIGHTING')) {
                    const SubLayout = gameMenu[sub.subMenuCode];
                    newArr = [...newArr, {
                        key: `${menuCode}_${sub.subMenuCode}_${index}`,
                        path: `/${gameCode}${sub.subMenuLink}`,
                        name: sub.subMenuName,
                        MenuLayout: SubLayout,
                        code: sub?.subMenuCode,
                        isBetting: isBetting
                    }]
                }
            })
        })
        setLoading(false);
        return newArr;
    }, [leftMenuList, gameCode]);

    return (
            <Switch>
                <Route exact path="/login" component={SignInPage}/>
                <Route exact path="/mainnew" component={LayoutNew(MainPage, true)}/>
                <PrivateRoute exact path="/main" component={LayoutNew(MainPage, true)}/>
                <PrivateRoute exact path="/4d/account/downline/details" component={Layout(DownlineDetail, 'Downline Details')}/>
                <PrivateRoute exact path="/toto/account/downline/details" component={Layout(TotoDownlineDetail, 'Downline Details')}/>
                <PrivateRoute exact path="/main/profile/current-downline-credit" component={LayoutNew(CurrentDownlineCredit, 'Downline Credit')}/>
                <PrivateRoute exact path="/tnc" component={Tnc}/>
                <PrivateRoute exact path="/newSimplebet" component={LayoutNew(SimpleBet)}/>
                <PrivateRoute exact path="/main/profile" component={LayoutNew(Profile)}/>
                <PrivateRoute exact path="/main/profile/setting" component={LayoutNew(Setting)}/>
                <PrivateRoute exact path="/main/profile/password" component={LayoutNew(Password)}/>
                <PrivateRoute exact path="/game/sport" component={LayoutNew(SportGame, '', true)}/>
                <PrivateRoute exact path="/game/esport" component={LayoutNew(EsportGame, '', true)}/>

                {memoizedArrayMenu?.map(menu => (
                    <PrivateRoute key={menu?.key} exact path={menu?.path} component={LayoutNew(menu?.MenuLayout, menu?.code,'', menu?.isBetting)}/>
                ))}

                {loading &&
                    <Route>
                        <Loading />
                    </Route>
                }
                {leftMenuList?.length > 0 &&
                    <Route>
                        <NoMatch/>
                    </Route>
                }

            </Switch>
    );
}

export default AppRouter;
