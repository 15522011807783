import {HYPER_LINK, LIST_TYPE_OF_TOTO} from "../constants/constants";
import "../assets/color.css";

export const convertDateDisplay = (date) => {
    const monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    const today = new Date(date);
    const dd   = today.getDate();
    const mm   = monthNames[today.getMonth()];
    const yyyy = today.getFullYear();
    const day  = days[today.getDay()];
    return  `${day}, ${dd} ${mm} ${yyyy}`;

}
export const convertDateFormatMonthFirst = (data) => {
    const arr = data?.split('/');
    return `${arr[1]}/${arr[0]}/${arr[2]}`
}
export const convertDateSubmit = (date) => {
    const today = new Date(date);
    const dd   = (today.getDate()).toString().padStart(2, "0");
    const mm   = (today.getMonth() + 1).toString().padStart(2, "0");
    const yyyy = today.getFullYear();
    return  `${dd}/${mm}/${yyyy}`;
}
export const listDateDummy = [
    {value: '1', title: '1:Wed|Sat|Sun', day: 3, background: 'pink'},
    {value: '2', title: '2:Sat|Sun', day: 2, background: 'var(--color67)'},
    {value: '3', title: '3:Wed|Sun', day: 2, background: 'green'},
    {value: '4', title: '4:Wed|Sat', day: 2, background: 'yellow'},
    {value: '5', title: '5:Wed', day: 1, background: 'var(--color68)'},
    {value: '6', title: '6:Sat', day: 1, background: 'var(--color69)'},
    {value: '7', title: '7:Sun', day: 1, background: 'var(--color70)'}
]
export const handleChangeTypeForAllBet = (value, listData, channel) => {
    return listData?.map(item => {
        if((channel === 'SavedBet' && (item?.id || !item?.checked)) || (channel === "FixedBet" && item?.id)){
            return item
        } else if(item?.number?.length>0 || Number(item?.big) > 0 || Number(item?.small) > 0) {
            const dateChoose = listDateDummy?.find(ld => ld?.value === item?.date);
            const totalDay = dateChoose?.day;
            const caculateBig = value !== 'iBet' ? (value === 'Roll'?  item?.big * checkCaculateForRoll(item?.number) * totalDay :  item?.big * totalDay ) : 0;
            const caculateSmall = value !== 'iBet' ?(value === 'Roll'?  item?.small * checkCaculateForRoll(item?.number) * totalDay :  item?.small * totalDay ) : 0;
            const caculateIBig = value === 'iBet' ? item?.big * totalDay : 0;
            const caculateISmall = value === 'iBet' ? item?.small * totalDay : 0;
            return {
                ...item,
                type: value,
                caculateBig: caculateBig,
                caculateSmall: caculateSmall,
                caculateIBig: caculateIBig,
                caculateISmall:caculateISmall,
                caculateTotal: Number(caculateBig) * 1.6 + Number(caculateSmall) * 0.7 + Number(caculateIBig) * 1.6 + Number(caculateISmall) * 0.7
            }
        } else {
            return {...item, type: value}
        }

    })
}
export const handleChangeDateForAllBet = (value, listData, channelBet) => {
    const totalDay = value?.length;
    return listData?.map((item) => {
        if((channelBet === 'SavedBet' && (item?.id || !item?.checked)) || (channelBet === "FixedBet" && item?.id)) {
            return item;
        } else if (
            item?.number?.length > 0 ||
            Number(item?.big) > 0 ||
            Number(item?.small) > 0
        ) {
            const caculateBig =
                item?.type !== "iBet"
                    ? item?.type === "Roll"
                        ? item?.big * checkCaculateForRoll(item?.number) * totalDay
                        : item?.big * totalDay
                    : 0;
            const caculateSmall =
                item?.type !== "iBet"
                    ? item?.type === "Roll"
                        ? item?.small * checkCaculateForRoll(item?.number) * totalDay
                        : item?.small * totalDay
                    : 0;
            const caculateIBig =
                item?.type === "iBet" ? item?.big * totalDay : 0;
            const caculateISmall =
                item?.type === "iBet" ? item?.small * totalDay : 0;
            return {
                ...item,
                drawDays: value,
                caculateBig: caculateBig,
                caculateSmall: caculateSmall,
                caculateIBig: caculateIBig,
                caculateISmall: caculateISmall,
                caculateTotal:
                    Number(caculateBig) * 1.6 +
                    Number(caculateSmall) * 0.7 +
                    Number(caculateIBig) * 1.6 +
                    Number(caculateISmall) * 0.7
            };
        }
        return {
            ...item,
            drawDays: value
        };
    })
}
export const getWedDay = (days, hours , dateCopy) => {
    return (days === 'WEDNESDAY' && hours < 16) ? new Date() : new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 3) % 7 || 7),
        ),
    );
}
export const getSatDay = (days, hours , dateCopy) => {
    return (days === 'SATURDAY' && hours < 16) ? new Date() : new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 6) % 7 || 7),
        ),
    );
}
export const getMonday = (days, hours , dateCopy) => {
    return (days === 'MONDAY' && hours < 16) ? new Date() : new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
        ),
    );
}
export const getSunDay = (days, hours , dateCopy) => {
    return (days === 'SUNDAY' && hours < 16) ? new Date() : new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 7) % 7 || 7),
        ),
    );
}

export const getAllNextDate = () => {
    const today = new Date();
    const dateCopy = new Date(today.getTime());
    const days = ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY'];
    const hours = today?.getHours();
    const day  = days[today.getDay()];
    let nextSartuday = getSatDay(day, hours, dateCopy)
    let nextSunday = getSunDay(day, hours, dateCopy);
    let nextWed = getWedDay(day, hours, dateCopy);
    const dateTmp = [nextSartuday, nextWed, nextSunday];
    return {
        date: {
            wedDay: nextWed,
            satDay: nextSartuday,
            sunDay: nextSunday
        },
        listDate: dateTmp?.sort((a, b) => a - b)
    }
}

export const parseDownLineList = (downlineOption, accountSelected) => {
    return  downlineOption?.map((item) =>{
            let downlines= null;
            if (item.downlines !== null){
                downlines = item?.downlines.map((item1) => {
                    return {label: `${item1.userId} (${item1?.userName})`, userName: item1?.userName, id: item1.userId, isDefaultValue: item1.userId === accountSelected}
                })
            }
            return { label: `${item.userId} (${item?.userName})`, userName: item?.userName, children: downlines, id: item.userId, isDefaultValue: item.userId === accountSelected}
        }
    )
}
export const genInnerLink = (message) => {
    if (message?.isDelete) {
        return '#'
    }
    if (HYPER_LINK.hasOwnProperty(message?.notificationType) && HYPER_LINK[message?.notificationType].hasOwnProperty(message?.function) && HYPER_LINK[message?.notificationType][message?.function].hasOwnProperty(message?.provider)) {
        return `${HYPER_LINK[message?.notificationType][message?.function][message?.provider]}${(message?.values?.itemId ? message?.values?.itemId : message?.values?.orderNo )|| ""}${message?.notificationType === 'cloud' ? '&providerType=' + message?.provider : ""}` || '';
    }
}
export const formatDate = (date) => {
    if (date) {
        const UNIX_timestamp = Date.parse(date.replace(/-/g, '/'));
        const a = new Date(UNIX_timestamp);
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        let day = a.getDate();
        let hour = a.getHours();
        let min = a.getMinutes();
        let sec = a.getSeconds();
        if (day < 10)
            day = '0' + day;
        if (hour < 10)
            hour = '0' + hour;
        if (min < 10)
            min = '0' + min;
        if (sec < 10)
            sec = '0' + sec;
        return `${[year, month, day].join('/')} ${[hour, min, sec].join(':')}`;
    } else {
        return '';
    }
}

export const checkCaculateForRoll = (number) => {
    const array = Array.from(number) || [];
    let list = [];
    array?.map(item => {
        if((list?.length>0 && !list?.some(item1 => item === item1)) || list?.length === 0){
            list = [...list, item]
        }
    });
    switch (list?.length) {
        case 4:
            return 24
        case 3:
            return 12;
        case 2:
            const count = array?.filter(s => s === list[0]);
            if(count?.length === 2) {
                return 6;
            } else {
                return 4;
            }
        default:
            return 1;
    }
}

export const detailNormal = (array) => {
    return array?.sort((a, b) => a - b)?.map(s => {
        return `${s}`;
    })?.toString();
}
export const detailTT = (No1, No2) => {
    let ttArr = [];
    for (let i = Number(No1); i <= Number(No2); ++i) {
        ttArr.push(i < 10 ? `0${i}` : `${i}`);
    }
    return ttArr;
}
export const detailT2 = (No1, No2) => {
    let ttArr = [No1];
    for (let i = Number(No1) + 1; i <= Number(No2); ++i) {
        ttArr.push(i < 10 ? `0${i}` : `${i}`);
    }
    return detailRoll2(ttArr);
}
export const detailT3 = (No1, No2, No3) => {
    let ttArr = [];

    for (let i = Number(No2); i <= Number(No3); ++i) {
        No1 != i && ttArr.push([No1, i < 10 ? `0${i}` : `${i}`]);
    }

    let arrReturn = [];
    ttArr?.forEach(row => {
        const tmp = row.sort((a, b) => a - b);
        if (!arrReturn.some(s => s === tmp?.toString())) {
            arrReturn.push(tmp?.toString())
        }
    })
    return arrReturn;
}
export const detailStartTwo = (No1) => {
    let start2Arr = [];
    for (let i = 1; i <= 49; ++i) {
        if (i !== Number(No1)) {
            start2Arr.push([`${No1}`, i < 10 ? `0${i}` : `${i}`]?.sort((a, b) => a - b)?.toString());
        }
    }
    return start2Arr;
}
export const detailStartThree = (No1, No2) => {
    let start2Arr = [];
    for (let i = 1; i <= 49; ++i) {
        if (i !== Number(No1) && i !== Number(No2)) {
            start2Arr.push([`${No1}`, `${No2}`, i < 10 ? `0${i}` : `${i}`]?.sort((a, b) => a - b)?.toString());
        }
    }
    return start2Arr;
}
export const detailTwoStart = (arr) => {
    let start2Arr = [];
    arr?.forEach((s, index) => {
        index > 0 && start2Arr.push([arr[0], s]?.sort((a, b) => a - b)?.toString())
    })
    return start2Arr;
}
export const detailThreeStart = (arr) => {
    let start2Arr = [];
    arr?.forEach((s, index) => {
        index > 1 && start2Arr.push([arr[0], arr[1], s]?.sort((a, b) => a - b)?.toString())
    })
    return start2Arr;
}
export const detailFourStart = (arr) => {
    let start2Arr = [];
    arr?.forEach((s, index) => {
        index > 2 && start2Arr.push([arr[0], arr[1], arr[2], s]?.sort((a, b) => a - b)?.toString())
    })
    return start2Arr;
}
export const detailRoll = (array) => {
    let sumArray = handleCombinations(array, 2, 4);
    let rollArr = [];
    sumArray?.forEach(row => {
        const tmp = row.sort((a, b) => a - b);
        if (!rollArr.some(s => s === tmp?.toString())) {
            rollArr.push(tmp?.toString())
        }
    })
    return rollArr;
}
export const detailRoll2 = (array) => {
    const sumArray = handleCombinations(array, 2, 2);
    let rollArr = [];
    sumArray?.forEach(row => {
        const tmp = row.sort((a, b) => a - b);
        if (!rollArr.some(s => s === tmp?.toString())) {
            rollArr.push(tmp?.toString())
        }
    })
    return rollArr;
}
export const detailRoll3 = (array) => {
    const sumArray = handleCombinations(array, 3, 3);
    let rollArr = [];
    sumArray?.forEach(row => {
        const tmp = row.sort((a, b) => a - b);
        if (!rollArr.some(s => s === tmp?.toString())) {
            rollArr.push(tmp?.toString())
        }
    })
    return rollArr;
}
export const detailRoll4 = (array) => {
    const sumArray = handleCombinations(array, 4, 4);
    let rollArr = [];
    sumArray?.forEach(row => {
        const tmp = row.sort((a, b) => a - b);
        if (!rollArr.some(s => s === tmp?.toString())) {
            rollArr.push(tmp?.toString())
        }
    })
    return rollArr;
}
export const detailRoll5 = (array) => {
    const sumArray = handleCombinations(array, 2, 3);
    let rollArr = [];
    sumArray?.forEach(row => {
        const tmp = row.sort((a, b) => a - b);
        if (!rollArr.some(s => s === tmp?.toString())) {
            rollArr.push(tmp?.toString())
        }
    })
    return rollArr;
}
export const detailRoll7 = (array) => {
    const sumArray = handleCombinations(array, 3, 4);
    let rollArr = [];
    sumArray?.forEach(row => {
        const tmp = row.sort((a, b) => a - b);
        if (!rollArr.some(s => s === tmp?.toString())) {
            rollArr.push(tmp?.toString())
        }
    })
    return rollArr;
}
export const permutations = (arr) => {
    return (arr.length === 1) ? arr :
        arr.reduce((acc, cv, index) => {
            let remaining = [...arr];
            remaining.splice(index, 1);
            return acc.concat(permutations(remaining).map(a => [].concat(cv, a)));
        }, []);
}

export const checkRangeNumber = (arr) => {
    let error = {}
    arr?.map((item, index) => {
        error = {...error, [`error${index}`]: (item && (Number(item) > 49 || Number(item) < 1))}
    })
    return error;
}
export const validateNormal = (arr) => {
    return arr?.length > 0 && arr?.length < 5;
}
export const validateTT = (arr) => {
    return arr?.length === 2;
}
export const validateT3 = (arr) => {
    return arr?.length === 3 && arr[2] > arr[1] && arr[2] > arr[0];
}
export const validateStartTwo = (arr) => {
    return arr?.length === 1;
}
export const validateStartThree = (arr) => {
    return arr?.length === 2;
}
export const validateTwoStart = (arr) => {
    return arr?.length >= 3;
}
export const validateThreeStart = (arr) => {
    return arr?.length >= 4;
}
export const validateFourStart = (arr) => {
    return arr?.length >= 5;
}
export const validateRollLength = (arr, length) => {
    return arr?.length >= length;
}

export const validateDuplicate = (arr) => {
    let rollArr = []
    arr?.map(row => {
        if (!rollArr.includes(row)) {
            const num = Number(row);
            rollArr.push(`${num}`)
        }
    });
    return arr?.length === rollArr?.length;
}
export const handleCombinationsNumber = (item, index) => {
    let arrray = [];
    let errmsg = '';
    const tmp = [item?.no1 !== '' && item?.no1 !== undefined && item?.no1?.length < 2 ? `0${item?.no1}` : item?.no1,
        item?.no2 !== '' && item?.no2 !== undefined && item?.no2?.length < 2 ? `0${item?.no2}` : item?.no2,
        item?.no3 !== '' && item?.no3 !== undefined && item?.no3?.length < 2 ? `0${item?.no3}` : item?.no3,
        item?.no4 !== '' && item?.no4 !== undefined && item?.no4?.length < 2 ? `0${item?.no4}` : item?.no4,
        item?.no5 !== '' && item?.no5 !== undefined && item?.no5?.length < 2 ? `0${item?.no5}` : item?.no5,
        item?.no6 !== '' && item?.no6 !== undefined && item?.no6?.length < 2 ? `0${item?.no6}` : item?.no6,
        item?.no7 !== '' && item?.no7 !== undefined && item?.no7?.length < 2 ? `0${item?.no7}` : item?.no7,
        item?.no8 !== '' && item?.no8 !== undefined && item?.no8?.length < 2 ? `0${item?.no8}` : item?.no8,
        item?.no9 !== '' && item?.no9 !== undefined && item?.no9?.length < 2 ? `0${item?.no9}` : item?.no9,
        item?.no10 !== '' && item?.no10 !== undefined && item?.no10?.length < 2 ? `0${item?.no10}` : item?.no10

    ]

    tmp?.map(row => {
        // check if undefined not push
        if(row){
            arrray.push(row)
        }
    });
    if(!validateDuplicate(arrray)) {
        errmsg= 'Not allow input the same number';
        return {errmsg: errmsg, details: {}};
    }
    if(!item?.no1 && !item?.no2 && arrray?.length > 0) {
        // check if number 1 and number 2 is all undefined
        errmsg= 'Should input in N1 or N2';
        return {errmsg: errmsg, details: {}, error: {errorN1: true, errorN2: true}};
    }
    if(arrray?.length === 0){
        return {errmsg: '', details: {}};
    }
    if(!item?.unit) {
        errmsg= 'Please input Unit';
        return {errmsg: errmsg, details: {}, error: {errorUnit: true}};
    }
    const error = checkRangeNumber(arrray);
    if (error?.error0 || error?.error1 || error?.error2 || error?.error3 || error?.error4 || error?.error5 || error?.error6 || error?.error7 || error?.error8 || error?.error9) {
        errmsg = 'Should input number from 01 to 49';
        return {
            errmsg: errmsg, details: {}, error: {
                errorN1: error?.error0, errorN2: error?.error1, errorN3: error?.error2, errorN4: error?.error3,
                errorN5: error?.error4, errorN6: error?.error5, errorN7: error?.error6, errorN8: error?.error7,
                errorN9: error?.error8, errorN10: error?.error9
            }
        };
    }
    let dataDetail = [];
    let validate = true;
    switch (item?.type) {
        case LIST_TYPE_OF_TOTO.NORMAL:
            validate = validateNormal(arrray);
            if (!validate) {
                errmsg = 'allow input maximum 4 numbers';
                return {
                    errmsg: errmsg, details: {}, error: {
                        errorN5: !!arrray[4], errorN6: !!arrray[5],
                        errorN7: !!arrray[6], errorN8: !!arrray[7],
                        errorN9: !!arrray[8], errorN10: !!arrray[9]
                    }
                };
            }
            dataDetail = [detailNormal(arrray)];
            break;
        case LIST_TYPE_OF_TOTO.TT:
            // only allow 2 number and the  n1 must be lower more than n2
            validate = validateTT(arrray);
            if (!validate) {
                errmsg = 'allow input 2 numbers only';
                return {errmsg: errmsg, details: {}, error: {errorN3: !!arrray[2], errorN4: !!arrray[3]}};
            }
            if (arrray[0] > arrray[1]) {
                errmsg = 'number 1 should be less then number 2';
                return {errmsg: errmsg, details: {}, error: {errorN1: item?.no1 && true, errorN2: !item?.no1 && true, errorN3: !!arrray[2], errorN4: !!arrray[3]}};
            }
            dataDetail = detailTT(arrray[0], arrray[1]);
            break;
        case LIST_TYPE_OF_TOTO.T_TWO:
            // only allow 2 number and the  n1 must be lower more than n2
            validate = validateTT(arrray);
            if (!validate) {
                errmsg = 'allow input 2 numbers only';
                return {errmsg: errmsg, details: {}, error: {errorN3: !!arrray[2], errorN4: !!arrray[3]}};
            }
            dataDetail = detailT2(arrray[0], arrray[1]);
            break;
        case LIST_TYPE_OF_TOTO.T_THREE:
            // only allow 3 number and no3 bigger more then number 1 and number 1x
            validate = validateT3(arrray);
            if (!validate) {
                errmsg = 'allow input 3 numbers only';
                return {errmsg: errmsg, details: {}, error: {errorN3: !!arrray[2], errorN4: !!arrray[3]}};
            }
            dataDetail = detailT3(arrray[0], arrray[1], arrray[2]);
            break;


        case LIST_TYPE_OF_TOTO.STAR_TWO:
            // only allow 1 number
            validate = validateStartTwo(arrray);
            if (!validate) {
                errmsg = 'allow input 1 number only';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN2: !!arrray[1], errorN3: !!arrray[2], errorN4: !!arrray[3]}
                };
            }
            dataDetail = detailStartTwo(arrray[0]);
            break;
        case LIST_TYPE_OF_TOTO.STAR_THREE:
            // only allow 1 number
            validate = validateStartThree(arrray);
            if (!validate) {
                errmsg = 'allow input 1 number only';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !!arrray[2], errorN4: !!arrray[3]}
                };
            }
            dataDetail = detailStartThree(arrray[0], arrray[1]);
            break;
        case LIST_TYPE_OF_TOTO.TWO_STAR:
            // allow >=3 number
            validate = validateTwoStart(arrray);
            if (!validate) {
                errmsg = 'allow input more than 3 number only';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !!arrray[2], errorN4: !!arrray[3]}
                };
            }
            dataDetail = detailTwoStart(arrray);
            break;
        case LIST_TYPE_OF_TOTO.THREE_STAR:
            // allow >=4 number
            validate = validateThreeStart(arrray);
            if (!validate) {
                errmsg = 'allow input more than 4 number only';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !!arrray[2], errorN4: !!arrray[3]}
                };
            }
            dataDetail = detailThreeStart(arrray);
            break;
        case LIST_TYPE_OF_TOTO.FOUR_STAR:
            // allow >=5 number
            validate = validateFourStart(arrray);
            if (!validate) {
                errmsg = 'allow input more than 5 number only';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !!arrray[2], errorN4: !!arrray[3]}
                };
            }
            dataDetail = detailFourStart(arrray);
            break;
        case LIST_TYPE_OF_TOTO.ROLL:
            // should be input >=3 number
            validate = validateRollLength(arrray, 3);
            if (!validate) {
                errmsg = 'need input more than 3 numbers';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !arrray[2], errorN4: !arrray[3]}
                };
            }
            dataDetail = detailRoll(arrray);
            break;
        case LIST_TYPE_OF_TOTO.ROLL_TWO:
            // should be input >=2 number
            validate = validateRollLength(arrray, 2);
            if (!validate) {
                errmsg = 'need input more than 2 numbers';
                return {errmsg: errmsg, details: {}, error: {errorN1: !arrray[0], errorN2: !arrray[1]}};
            }
            dataDetail = detailRoll2(arrray);
            break
        case LIST_TYPE_OF_TOTO.ROLL_THREE:
            // should be input >=3 number
            validate = validateRollLength(arrray, 3);
            if (!validate) {
                errmsg = 'need input more than 3 numbers';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !arrray[2]}
                };
            }
            dataDetail = detailRoll3(arrray);
            break
        case LIST_TYPE_OF_TOTO.ROLL_FOUR:
            // should be input >=4 number
            validate = validateRollLength(arrray, 4);
            if (!validate) {
                errmsg = 'need input more than 4 numbers';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !arrray[2], errorN4: !arrray[3]}
                };
            }
            dataDetail = detailRoll4(arrray);
            break
        case LIST_TYPE_OF_TOTO.ROLL_FIVE:
            // should be input >=3 number
            validate = validateRollLength(arrray, 3);
            if (!validate) {
                errmsg = 'need input more than 3 numbers';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !arrray[2]}
                };
            }
            dataDetail = detailRoll5(arrray);
            break
        case LIST_TYPE_OF_TOTO.ROLL_SEVEN:
            // should be input >=3 number
            validate = validateRollLength(arrray, 3);
            if (!validate) {
                errmsg = 'need input more than 3 numbers';
                return {
                    errmsg: errmsg,
                    details: {},
                    error: {errorN1: !arrray[0], errorN2: !arrray[1], errorN3: !arrray[2]}
                };
            }
            dataDetail = detailRoll7(arrray);
            break
        default:
            break;
    }
    return {errmsg: errmsg, details: {list: dataDetail, count: dataDetail?.length, index: index + 1, unit: item?.unit}, error: {}};
}

export const handleCaculate = (arr) => {
    return arr?.map((item, index) => {
        const detailsTmp = handleCombinationsNumber(item, index);
        const list = detailsTmp?.details?.list?.map(d => {
            return d.split(",");
        })
        const totalDays = item?.date?.length || 1;
        const total = item?.unit * detailsTmp?.details?.count * totalDays || 0;
        const totalD1 = item?.unit * list?.filter(s => s?.length === 1)?.length * totalDays || 0;
        const totalD2 = item?.unit * list?.filter(s => s?.length === 2)?.length * totalDays|| 0;
        const totalD3 = item?.unit * list?.filter(s => s?.length === 3)?.length * totalDays|| 0;
        const totalD4 = item?.unit * list?.filter(s => s?.length === 4)?.length * totalDays|| 0;
        return {
            ...item,
            'totalD1': totalD1,
            'totalD2': totalD2,
            'totalD3': totalD3,
            totalD4: totalD4,
            total: total,
            error: detailsTmp?.error,
            errorMesage: detailsTmp?.errmsg
        }
    });
}
export const handleCombinations = (arr, min, max) => {
    return combo(arr, min, max);
}

var combo = (a, min, max) => {
    min = min || 1;
    max = max < a.length ? max : a.length;
    var fn = (n, src, got, all) => {
        if (n == 0) {
            if (got.length > 0) {
                all[all.length] = got;
            }
            return;
        }
        for (var j = 0; j < src.length; j++) {
            fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
        }
        return;
    }
    var all = [];
    for (var i = min; i < a.length; i++) {
        fn(i, a, [], all);
    }
    if (a.length == max) {
        all.push(a);
    }
    return all?.filter(s => s.length <= max);
}
export const convertArray = (inputArray, idSelected) => {
    if (!inputArray || inputArray.length === 0) {
        return [];
    }

    const { userId, userName, downlines } = inputArray[0];

    const outputArray = [{
        label: `${userId} (${userName})`,
        userName,
        children: [],
        id: userId,
        isDefaultValue: userId === idSelected
    }];

    function processDownlines(downlines, parent) {
        if (!downlines || downlines.length === 0) {
            return;
        }

        downlines.forEach(downline => {
            const { userId, userName, downlines } = downline;

            const downlineObj = {
                label: `${userId} (${userName})`,
                userName,
                id: userId,
                isDefaultValue: userId === idSelected
            };

            if (downlines && downlines.length > 0) {
                downlineObj.children = [];
                processDownlines(downlines, downlineObj);
            }

            parent.children.push(downlineObj);
        });
    }

    processDownlines(downlines, outputArray[0]);

    return outputArray;
}

export function validateBigOrSmall(num){
    const regex = /^\d+(\.[05]?)?$/;
    return regex.test(num);
}