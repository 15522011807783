import { createTheme } from "@mui/material/styles";
import "../../../assets/color.css";
export const mdTheme = (globalLanguage) => createTheme({
    typography: {
        fontFamily: (globalLanguage === 'en' || globalLanguage === 'en-us')? "Roboto, sans-serif" : "Noto Sans TC, sans-serif",
        fontSize: 12,
    },
    transitions: {
        duration: {
            enteringScreen: 1000,
            leavingScreen: 1000,
        },
    },
    palette: {
        primary: {main:'#1F2B50'},
    },
    components: {
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    "&.Mui-active": {
                        color: "var(--color2)"
                    },
                    "&.MuiSvgIcon-root":{
                        height:'36px',
                        width:'36px'
                    },
                    "&.MuiStepConnector-horizontal":{
                        visibility:'hidden'
                    }
                },
                text: {
                    fill: "var(--color3)",
                }
            }
        }
    },
});