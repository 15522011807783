import { combineReducers } from "redux";
import auth from "./auth";
import language from "./language";
import common from "./common";
import result from "./MainManagement/Result/result";
import announcement from "./MainManagement/Announcement/announcement";
import subAccount from "./AccountManagement/SubAccount/subAccount";
import alert from "./alert";
import simpleBet from "./BettingManagement/simpleBet";
import fixedBet from "./BettingManagement/fixedBet";
import fightingHistory from "./AccountManagement/FightingHistory/FightingHistory";
import totoBettingHistory from "./Toto/BetManagement/BetHistory";
import currentDownlineCredit from "./AccountManagement/Downline/currentDownlineCredit";
//persist
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import downline from "./AccountManagement/Downline/downline";
import massBet from "./BettingManagement/massBet";
import bettingHistory from "./BettingManagement/bettingHistory";
import massBetTwo from "./BettingManagement/massBetTwo";
import transactionHistory from "./ViewLog/transactionHistory";
import systemHistory from "./ViewLog/systemHistory";
import onlineList from "./AccountManagement/OnlineList/onlineList";
import savedBet from "./BettingManagement/savedBet";
import loginHistory from "./ViewLog/loginHistory";
import notification from "./socket";
import fullReport from "./ReportManagement/fullReport";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["language"],
};

const appReducer = combineReducers({
  auth,
  language,
  common,
  result,
  announcement,
  subAccount,
  downline,
  alert,
  simpleBet,
  massBet,
  massBetTwo,
  bettingHistory,
  transactionHistory,
  onlineList,
  savedBet,
  fixedBet,
  loginHistory,
  systemHistory,
  notification,
  fightingHistory,
  totoBettingHistory,
  fullReport,
  currentDownlineCredit
});

const rootReducer = (state, action) => {
  if (action.type === "LOGIN_AS_SUCCESS" || action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
