import React from "react";
import { Route, Redirect } from "react-router-dom";
//Redux
import { connect } from "react-redux";

const PrivateRoute = ({
                          component: Component,
                          auth: { isAuthenticated, loading,  user },
                          env,
                          permit,
                          mainMenu,
                          menuCode,
                          selectedGame,
                          ...rest
                      }) => {

    return (
        <Route
            {...rest}
            render={(props) =>(
                    (!isAuthenticated && !loading) ? (
                        <Redirect to="/login" />
                    ) : (
                        <Component {...props} />
                    )
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    selectedGame: state.common.selectedGame,
});

export default connect(mapStateToProps, {})(PrivateRoute);
