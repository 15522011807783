import React from "react";
import {makeStyles} from '@mui/styles';
import Button from "@mui/material/Button";
import { ACTION_BTN_CLASSNAMES, ACTION_BTN_VARIANTS } from "constants/constants";
import "../../../assets/color.css";

const useStyles = makeStyles({
    root: {
        '&.outline-btn, &.primary-btn': {
            width: '80px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '30px',
            transition: 'all ease 325ms',
            willChange: 'background',
            maxWidth: '100%',

            // Mobile devices
            '@media screen and (max-width: 767px)': {
                minWidth: '200px',
                width: '100%',
            },
        },

        '&.outline-btn': {
            border: '1px solid var(--color16)',
            backgroundColor: 'var(--neutral-1000-day)',
            color: 'var(--neutral-00-day)',
            '&:hover, &:active': { backgroundColor: 'var(--color15)' },
        },

        '&.primary-btn': {
            border: '1px solid transparent',
            color: 'var(--neutral-00-day)',
            backgroundColor: 'var(--color16)',
            '&.green-color': {
                backgroundColor: 'var(--Green-100)',
            },
            '&:hover, &:active': {
                boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
                backgroundColor: 'rgb(21, 30, 56)',
                },
        },
    },

    label: {
        textTransform: "capitalize",
            letterSpacing: "0.05em",
            fontSize: '14px',
            fontWeight: '400',
            '&.outline-btn': { color: 'var(--neutral-00-day) !important' },
        '&.primary-btn': { color: 'var(--color3) !important' },
    },
})

export default function DisagreeButton({
                                           variant,
                                           name,
                                           disabled,
                                           handleClick,
                                           customBtnClassName,
                                           width,
                                           height,
                                           borderRadius
                                       }) {
    const classes = useStyles();
    const btnVariant = variant || ACTION_BTN_VARIANTS.OUTLINED;
    const className = customBtnClassName || ACTION_BTN_CLASSNAMES[btnVariant];

    return (
        <Button
            color="primary"
            variant="outlined"
            disabled={disabled}
            className={`${classes.root} ${className}`}
            onClick={handleClick}
            sx={{width: width ? width : '120px', height: height? height :'35px', borderRadius: `${borderRadius} !important` || '30px'}}
        >
            <span className={`${classes.label} ${className}`}>{name}</span>
        </Button>
    );

}