import {
    CHECK_USERID_IS_AVAILABLE,
    CREATE_SUB_ACCOUNT, EDIT_SUB_ACCOUNT,
    GET_LIST_ACCOUNT_OPTION,
    GET_LIST_SUB_ACCOUNT, GET_SUB_ACCOUNT_DETAIL_BY_ID, RESET_CHECK_STATUS
} from "../../../actions/type";

const initialState = {
    subAccountList: [{userId: 'SMA_0001', userName: 'Donnap_sub'}],
    accountOption: {userId: 'MA_0001', userName: 'Donnap', downLineList: [{userId: 'AG_0001', userName: 'Donnap123'}, {userId: 'AG_0002', userName: 'Donnap123456'}]},
    isUpdate: false,
    checkStatus: null,
    subAccountDetail: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_SUB_ACCOUNT:
            return {
                ...state,
                subAccountList: payload
            }
        case GET_LIST_ACCOUNT_OPTION:
            return {
                ...state,
                accountOption: payload
            }
        case CREATE_SUB_ACCOUNT:
        case EDIT_SUB_ACCOUNT:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        case CHECK_USERID_IS_AVAILABLE:
            return {
                ...state,
                checkStatus: payload
            }
        case GET_SUB_ACCOUNT_DETAIL_BY_ID:
            return {
                ...state,
                subAccountDetail: payload
            }
        case RESET_CHECK_STATUS:
            return {
                ...state,
                checkStatus: null
            }
        default:
            return state;
    }
}
