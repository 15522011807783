import {Checkbox, FormControlLabel, OutlinedInput, Stack, Typography} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AgreeButton from "../../../../../src/components/button/dialog/AgreeButton";
import DisagreeButton from "../../../../../src/components/button/dialog/DisagreeButton";
import { useOutlinedInputStyles } from "../../../../components/input/useOutlinedInputStyles";
import { useOutlinedInputStylesError } from "../../../../components/input/useOutlinedInputStylesError";
import { ToastError } from "../../../../components/toast/ToastError";
import { GetDetectBrowserInfo } from 'constants/constants';
import {dialogStyles} from "../../../../components/style/DialogStyle.styles";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import {CustomSelect} from "../../../../componentsToto/select/CustomSelect";
import {renderValueSelect} from "../../../../componentsToto/select/RenderValueSelect";
import {StyledOption} from "../../../../componentsToto/select/StyledOption";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const ModalAPIKeyCreate = (props) => {
    const {t} = useTranslation();
    const classes = dialogStyles();

    const { isDesktop, isLaptop, isTablet } = GetDetectBrowserInfo();

    const {onClose, open, GenerateApiKey} = props;
    const radioGroupRef = useRef(null);
    const outlinedInputClasses = useOutlinedInputStyles();
    const outlinedInputStylesError = useOutlinedInputStylesError();
    const [msgError, setMsgError] = useState('');
    const [error, setError] = useState({});
    const [openToast, setOpenToast] = useState(false);
    const [objCreate, setObjCreate] = React.useState({
        validThru: new Date(),
        name: '',
        isNormalBet: true,
        isViewBetHistory: true
    });

    useEffect(() => {
        if(open) {
            setObjCreate({
                validThru: new Date(),
                name: '',
                isNormalBet: true,
                isViewBetHistory: true
            })
        }
    }, [open]);

    const handleChange = (value, name) => {
        setObjCreate({...objCreate, [name]: value});
    }

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        const errorName = objCreate?.name === undefined || objCreate?.name?.length === 0;
        const errorDate = objCreate?.validThru === undefined || objCreate?.validThru === '';
        const errorScope = objCreate?.isNormalBet === false && objCreate?.isViewBetHistory === false;
        setError({
            errorName,
            errorDate
        })
        if(errorName || errorDate) {
            setOpenToast(true);
            setMsgError(t("requiredField"));
        } else if(errorScope) {
            setOpenToast(true);
            setMsgError(t("Please choose at least one scope"));
        } else {
            setOpenToast(false);
            setMsgError('');
            let apiScope = [];
            if(objCreate?.isNormalBet){
                apiScope = ['PLACE_NORMAL_BET']
            }
            if(objCreate.isViewBetHistory) {
                apiScope = [...apiScope, 'VIEW_BETTING_HISTORY']
            }
            GenerateApiKey(objCreate?.name, objCreate?.validThru, apiScope);
            onClose();

        }
    };

    const closeToast = () => {
        setOpenToast(false);
    }

    return (
        <Dialog
            fullWidth
            className={classes.dialogContainer}
            TransitionProps={{onEntering: handleEntering}}
            open={open}
        >
            <DialogTitle className={classes.dialogLabel}>{t("Generate API Key")}</DialogTitle>
            <DialogContent className={classes.formContainer}>
                <Grid container spacing={2} display={'block'}>

                    <Grid item sx={12}>
                        <Grid container>
                            <Grid
                                item
                                className={classes.formLabelContainer}
                                xs={
                                    (isDesktop || isLaptop) ? 3
                                        : isTablet ? 4
                                        : 12
                                }
                            >
                                <Typography className={classes.formLabel}>{t("Name")}</Typography>
                            </Grid>

                            <Grid
                                item
                                xs={
                                    (isDesktop || isLaptop) ? 9
                                        : isTablet ? 8
                                        : 12
                                }
                                sx={{
                                    '& .MuiInputBase-root.MuiOutlinedInput-root': {
                                        background: '#fff',
                                        height: '45px'
                                    }
                                }}
                            >
                                <FormControl fullWidth size="small">
                                    <OutlinedInput
                                        value={objCreate?.name || ""}
                                        onChange={(e) => handleChange(e.target.value, "name")}
                                        classes={error?.errorName ? outlinedInputStylesError : outlinedInputClasses}
                                        placeholder={t("Please enter a name")}
                                        inputProps={{ maxLength: 30}}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={12}>
                        <Grid container>
                            <Grid
                                item
                                className={classes.formLabelContainer}
                                xs={
                                    (isDesktop || isLaptop) ? 3
                                        : isTablet ? 4
                                            : 12
                                }
                            >
                                <Typography className={classes.formLabel}>{t("Scope")}</Typography>
                            </Grid>

                            <Grid item xs={
                                (isDesktop || isLaptop) ? 9
                                    : isTablet ? 8
                                        : 12
                                }
                                  sx={{'& .MuiSelectUnstyled-root': {
                                      borderRadius: '5px'
                                      }}}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        icon={<RadioButtonUncheckedIcon sx={{color: 'var(--color59)', width: '25px', height: '25px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{color: 'var(--color2)', width: '25px', height: '25px'}}/>}
                                        onChange={e => handleChange(!objCreate?.isNormalBet, 'isNormalBet')}
                                        checked={objCreate?.isNormalBet}
                                    />}
                                    label={t("Place Normal Bet")}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        icon={<RadioButtonUncheckedIcon sx={{color: 'var(--color59)', width: '25px', height: '25px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{color: 'var(--color2)', width: '25px', height: '25px'}}/>}
                                        onChange={e => handleChange(!objCreate?.isViewBetHistory, 'isViewBetHistory')}
                                        checked={objCreate?.isViewBetHistory}
                                    />}
                                    label={t("View Bet History")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={12}>
                        <Grid container>
                            <Grid
                                item
                                className={classes.formLabelContainer}
                                xs={
                                    (isDesktop || isLaptop) ? 3
                                        : isTablet ? 4
                                        : 12
                                }
                            >
                                <Typography className={classes.formLabel}>{t("Valid Date")}</Typography>
                            </Grid>

                            <Grid
                                item
                                xs={
                                    (isDesktop || isLaptop) ? 9
                                        : isTablet ? 8
                                        : 12
                                }
                                sx={{
                                    "& .MuiInputBase-root": {
                                        background: 'white',
                                        height: '44px',
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 'none'
                                        }
                                    }
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack spacing={1}>
                                        <DesktopDatePicker
                                            label={t("Valid Date")}
                                            inputFormat="MM/DD/YYYY"
                                            value={objCreate.validThru}
                                            minDate={new Date()}
                                            onChange={(newValue) => handleChange(newValue, 'validThru')}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions className={classes.actionsContainer}>
                <Grid container item justifyContent='left' spacing={2}>
                    <Grid item xs={(isDesktop || isLaptop || isTablet) ? 'auto' : 12}>
                        <DisagreeButton
                            name={t('cancel')}
                            handleClick={handleCancel}
                            customBtnClassName={'outline-btn'}
                        />
                    </Grid>

                    <Grid item xs={(isDesktop || isLaptop || isTablet) ? 'auto' : 12}>
                        <AgreeButton
                            name={t('Confirm')}
                            handleClick={handleOk}
                            customBtnClassName={'primary-btn'}
                        />
                    </Grid>
                </Grid>
            </DialogActions>

            {openToast && <ToastError msg={msgError} closeToast={closeToast}/>}
        </Dialog>
    );
}
const mapStateToProps = () => ({
});
export default connect(mapStateToProps, {})(ModalAPIKeyCreate);