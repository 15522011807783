import React, {useEffect, useState} from 'react';
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//REDUX
import {changeLanguage} from 'actions/language';
import {connect} from 'react-redux';
//Utils
import { useTranslation } from "react-i18next";
import "../../../assets/color.css";
import './style.css'

const useStyles = makeStyles({
    translation:{
        color:'var(--color3)',
        cursor:'pointer'
    },
    currentLanguage: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.05em',
        border: '1px slolid var(--color23)'
    },
})


const Credit = (props) => {
    const {credit, avaiable, balance} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Badge badgeContent={0} color="secondary"  onClick={handleClick} className={classes.translation}>
                <Typography variant='body1' className={classes.currentLanguage}>{credit}</Typography>
                <KeyboardArrowDownIcon style={{color:'var(--color3)'}}/>
            </Badge>

            <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>{avaiable}</MenuItem>
                <MenuItem>{balance}</MenuItem>
            </Menu>
        </div>
    );
};

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps,{})(Credit);