import {SELECTED_GAME, SELECTED_LIST_ITEM, TOGGLE_LEFT_SIDE_BAR} from "./type";

export const toggleLeftSideBar = (open) => (dispatch) =>{
    dispatch({type:TOGGLE_LEFT_SIDE_BAR, payload:open})
}

export const selectItem = (number) => (dispatch) => {
    dispatch({ type: SELECTED_LIST_ITEM, payload: number });
};

export const selectGame = (game) => (dispatch) => {
    dispatch({ type: SELECTED_GAME, payload: game });
};
