
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled, ThemeProvider } from "@mui/material/styles";
import { GetDetectBrowserInfo } from "constants/constants";
import { mdTheme } from './Theme';
import User from "../../GlobalManagement/navBar/User";
import { connect } from "react-redux";
import {CssBaseline, Drawer, Tab, Tabs} from "@mui/material";
import {selectGame, selectItem, toggleLeftSideBar} from "../../../actions/common";
import MainListItems from "./ListItems";
import React, {Fragment, useEffect, useState} from "react";
import AlertSuccess from "../../../components/alert/AlertSuccess";
import AlertFail from "../../../components/alert/AlertFail";
import {makeStyles} from "@mui/styles";
import {getListNews} from "../../../actions/announcement/announcement";
import "../../../assets/color.css";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import {useTranslation} from "react-i18next";
import PlaylistRemoveOutlinedIcon from "@mui/icons-material/PlaylistRemoveOutlined";
import TocOutlinedIcon from "@mui/icons-material/TocOutlined";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "var(--color16)",
    height: '60px',
    boxShadow: 'unset'
}));
const useStyles = makeStyles({
    bar: {
        // position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: '0',
        backgroundColor: 'var(--color73)',
        color: 'white',
        fontFamily: 'Gelasio',
        fontSize: '14px'
    },
    bar_content: {
        color: 'var(--color16)',
        fontWeight: '700',
        display: 'block', /* Important to give the content a width */
        width: '100%',
        maxHeight: '25px',
        transform: 'translateX(100%)', /* Animation start out of the screen */
        /* Add the animation */
        animation: '$move 30s linear infinite',
        whiteSpace: 'nowrap',
        "&:hover": {
            animationPlayState: "paused"
        }
    },

    /* Create the animation */
    '@keyframes move': {
        'to': { transform: 'translateX(-100%) '}
    },
    drawerMenuIcon: {
        '--size': '1.6rem',
        width: 'var(--size)',
        height: 'var(--size)',
    },
    box: {
        height: "35px",
        marginBottom: "7px",
        alignItems: 'center',
        display: 'flex',
        paddingLeft: '10px',
        '&.drawer-menu-icon': {
            padding: '0.7rem',
            marginBottom: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all ease 325ms',
            willChange: 'opacity',

            '&:hover, &:active': {opacity: 0.8},
        },
    },
});
function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{pt: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Layout = (Component, pageLabel, hideLeftMenu) => {
    const ComposedComponent = ({
                                   globalLanguage,
                                   toggleLeftSideBar, sideBarStatus, status, errorStatus,
                                   notifyContent,
                                   getListNews,
                                   selectedGame,
                                   selectGame,
                                   selectItem
    }) => {
        const {isDesktop, isLaptop, isTablet} = GetDetectBrowserInfo();
        const classes = useStyles();
        const {t} = useTranslation()
        const [tab, setTab] = useState(0);
        const history = useHistory();
        const [isDrawerOpened, setIsDrawerOpened] = useState(false);

        useEffect(() => {
            pageLabel && selectItem(pageLabel);
        }, [pageLabel, selectItem]);

        const onToggleDrawerMenu = () => {
            setIsDrawerOpened((isOpen) => !isOpen);
        };

        useEffect(() => {
            getListNews()
        }, [])

        const handleChangeTab = (event, newValue) => {
            setTab(newValue);
        }

        const demos = {
            soundcloud:
                '<iframe width="100%" height="800px" scrolling="no" frameborder="no" allow="autoplay" src="https://kf53k50.oreo88.com/en/compact/sports"></iframe>',
            soundcloud2:
                '<iframe width="100%" height="800px" scrolling="no" frameborder="no" allow="autoplay" src="https://fv-kf53k50.oreo88.com/en/esports-hub/"></iframe>',
        };
        const Iframe = (props) => {
            return (
                <div
                    dangerouslySetInnerHTML={{__html: props.iframe ? props.iframe : ""}}
                />
            );
        }
        const handleChooseGame = (value) => {
            if (value === selectedGame) {
                return;
            }
            if(value === 'result') {
                let url = `/${selectedGame}/main/result`;
                history.push(url);
                return;
            }
            selectGame(value)
            let url = '/toto/betting/simple-bet'.replace(selectedGame, value);
            history.push(url);
        }
        return (
            <ThemeProvider theme={mdTheme(globalLanguage)}>
                <Box sx={{display: "flex", overflow: 'hidden'}}>
                    <CssBaseline/>
                    <Grid container
                          sx={{
                              position: "fixed",
                              overflow: 'hidden',
                              height: isDesktop || isLaptop ? '110px' : '150px',
                              zIndex: '99',
                              }}
                    >
                        <Grid item xs={12}>
                            <AppBar position="absolute">
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sx={{backgroundColor: "var(--color16)"}}>
                                        <Grid container justifyContent="flex-end" alignItems='center'>
                                            <Grid item xs={12}>
                                                <Box>
                                                    <User hideLeftMenu={hideLeftMenu}/>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ width: '100%', height:'45px' }}>
                                            <Fragment>
                                                <Box sx={{background: 'var(--color102)' , paddingBottom: "0px"}}>
                                                    <Grid item xs={12}>
                                                        <Box sx={{textTransform: 'none',
                                                            ".Mui-selected": {
                                                                color: '#D4AF37 !important'
                                                            },
                                                            ".MuiButtonBase-root": {
                                                                minHeight: '48px',
                                                                padding :'0px 2px 0px 10px'
                                                            }
                                                        }}>
                                                            <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example" TabIndicatorProps={{style: { background: '#EBEBEB', height: '0px'}}}>
                                                                <Tab icon={<EmojiEventsIcon/>} iconPosition="start" label={t("Lottery")} {...a11yProps(0)} sx={{fontSize: '12px', fontWeight: '500', color:'#F6F6F6',}}/>
                                                                <Tab icon={<SportsSoccerIcon/>} iconPosition="start" label={t("Sports")} {...a11yProps(1)} sx={{fontSize: '12px', fontWeight: '500', color:'#F6F6F6'}}/>
                                                                <Tab icon={<SportsEsportsIcon/>} iconPosition="start" label={t("ESports")} {...a11yProps(1)} sx={{fontSize: '12px', fontWeight: '500', color:'#F6F6F6'}}/>
                                                            </Tabs>
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            </Fragment>
                                        </Box>
                                    </Grid>
                                    {!(isDesktop || isLaptop)
                                        &&
                                        <Grid item xs={12} sx={{borderTop: '1px solid #fff'}}>
                                            <Box sx={{ width: '100%', height:'36px' }}>
                                                <Fragment>
                                                    <Box sx={{background: 'var(--color102)' , paddingBottom: "0px"}}>
                                                        <Grid item xs={12}>
                                                            <Box sx={{textTransform: 'none',
                                                                ".Mui-selected": {
                                                                    color: '#D4AF37 !important'
                                                                },
                                                                ".MuiButtonBase-root": {
                                                                    minHeight: '48px',
                                                                    padding :'0px 2px 0px 10px'
                                                                }
                                                            }}>
                                                                <Grid container sx={{paddingBottom:'1px'}}>
                                                                    <Grid item xs={3}>
                                                                        <Box className={`${classes.box} drawer-menu-icon`} onClick={onToggleDrawerMenu}>
                                                                            {
                                                                                isDrawerOpened
                                                                                    ? <PlaylistRemoveOutlinedIcon className={classes.drawerMenuIcon}/>
                                                                                    : <TocOutlinedIcon className={classes.drawerMenuIcon}/>
                                                                            }
                                                                        </Box>
                                                                        <Drawer open={isDrawerOpened} onClick={onToggleDrawerMenu} transitionDuration={500}
                                                                                sx={{
                                                                                    "& .MuiDrawer-paper": {
                                                                                        top: "8rem"
                                                                                    }
                                                                                }}
                                                                        >
                                                                            <MainListItems hideGame={true} toggleDrawer={isDrawerOpened}/>
                                                                        </Drawer>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Button
                                                                            sx={{
                                                                                fontWeight: selectedGame === '4d' ? '700': '400',
                                                                                width: '100%', minHeight: '35px !important', height: '35px !important', borderLeft: '1px solid #fff', borderRight: '1px solid #fff', color: selectedGame === '4d' ? '#D4AF37' :'#fff', borderRadius: '0px'}}
                                                                            onClick={() => handleChooseGame('4d')}
                                                                        >4D</Button>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Button
                                                                            sx={{
                                                                                fontWeight: selectedGame === 'toto' ? '700': '400',
                                                                                width: '100%', minHeight: '35px !important', height: '35px !important', borderLeft: '1px solid #fff', color: selectedGame === 'toto' ? '#D4AF37' :'#fff', borderRadius: '0px'}}
                                                                            onClick={() => handleChooseGame('toto')}
                                                                        >TOTO</Button>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Button
                                                                            onClick={() => handleChooseGame('result')}
                                                                            sx={{
                                                                            width: '100%', minHeight: '35px !important', height: '35px !important', borderLeft: '1px solid #fff', color: '#fff', borderRadius: '0px'}}
                                                                        >Result</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    </Box>
                                                </Fragment>
                                            </Box>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <div className={classes.bar}>
                                           <span className={classes.bar_content}>
                                               {notifyContent}
                                           </span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </AppBar>
                        </Grid>
                    </Grid>

                    <Box sx={{background:'#f1f1f1', width: '100%'}}>
                        <TabPanel value={tab} index={0} >
                            <Grid container p={(isDesktop || isLaptop || isTablet) ? '100px' : '0'}
                                sx={{
                                    padding: (isDesktop || isLaptop || isTablet) ? '100px' : '0',
                                    paddingTop: (isDesktop || isLaptop) ? '100px' : '140px',
                                }}
                            >
                                { !hideLeftMenu && (isDesktop || isLaptop) &&
                                    <Grid item xs={3}>
                                        <Grid container
                                              sx={{
                                                  position: "fixed",
                                                  overflow: 'hidden',
                                                  maxWidth:'21%',
                                              }}

                                        >
                                            <Grid

                                                  sx={{
                                                      width:' 100%',
                                                      backgroundColor: '#fff'}}
                                            >
                                                <MainListItems toggleDrawer={sideBarStatus}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={(isDesktop || isLaptop) ? 9 : 12}>
                                    <Box sx={{
                                        pl: sideBarStatus
                                            ? (
                                                isDesktop ? '0'
                                                    : isLaptop ? '0'
                                                        : isTablet ? '1.5rem'
                                                            : '1rem'
                                            )
                                            : (
                                                isDesktop ? '100px'
                                                    : isLaptop ? '60px'
                                                        : isTablet ? '1.5rem'
                                                            : '1rem'
                                            )
                                        ,
                                        pr: sideBarStatus
                                            ? (
                                                isDesktop ? '20px'
                                                    : isLaptop ? '0px'
                                                        : isTablet ? '1.5rem'
                                                            : '1rem'
                                            )
                                            : (
                                                isDesktop ? '100px'
                                                    : isLaptop ? '60px'
                                                        : isTablet ? '1.5rem'
                                                            : '1rem'
                                            )
                                        ,
                                        pb: isDesktop ? '50px'
                                            : isLaptop ? '50px'
                                                : isTablet ? '1.5rem'
                                                    : '1.5rem',
                                        minHeight: "70vh",
                                        overflow: 'auto'

                                    }}>
                                        <Component/>
                                    </Box>
                                    {status === true ? <AlertSuccess/> : null}
                                    {errorStatus === true ? <AlertFail/> : null}
                                </Grid>

                            </Grid>
                        </TabPanel>
                        <TabPanel value={tab} index={1} sx={{
                            ".MuiBox-root": {
                                paddingTop: "0px"
                            }
                        }}>
                            <Iframe iframe={demos["soundcloud"]} allow="autoplay" />
                        </TabPanel>
                        <TabPanel value={tab} index={2} >
                            <Iframe iframe={demos["soundcloud2"]} allow="autoplay" />
                        </TabPanel>
                    </Box>
                </Box>
            </ThemeProvider>
        );

    };
    const mapStateToProps = (state) => ({
        globalLanguage: state.language.language,
        sideBarStatus: state.common.lefSideBarControl,
        errorStatus: state.alert.errorStatus,
        status: state.alert.status,
        notifyContent: state.announcement.notifyContent,
        selectedGame: state.common.selectedGame,
    });
    return connect(mapStateToProps, {toggleLeftSideBar, getListNews, selectGame, selectItem})(ComposedComponent);
}

export default Layout;