import * as React from 'react';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/material/styles';
import "../../assets/color.css";

const blue = {
    100: 'var(--color27)',
    200: 'var(--color28)',
    400: 'var(--color29)',
    500: 'var(--color30)',
    600: 'var(--color31)',
    900: 'var(--color32)',
};

const grey = {
    100: 'var(--color33)',
    200: 'var(--color34)',
    300: 'var(--color34)',
    400: 'var(--color35)',
    500: 'var(--color36)',
    600: 'var(--color37)',
    700: 'var(--color38)',
    800: 'var(--color39)',
    900: 'var(--color40)',
};

const StyledButton = (bg, borderRadius, width, height) => styled('button')(
    ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: var(--color11);
  box-sizing: border-box;
  background: ${bg || 'var(--color3)'};
  border: 1px solid var(--color42);
  border-radius: ${borderRadius || '15px'};
  padding: 0 10px 0 25px;
  text-align: left;
  width: ${width || '200px'};
  height: ${height || '40px'};
  cursor: pointer;
  transition: all ease 325ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
      color: var(--color20);
    }
  }
  &::after {
    content: '▾';
    float: right;
    color: var(--color20);
  }
  `,
);

const StyledButtonError = ( width, height) => styled('button')(
    ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? grey[900] : 'var(--color3)'};
  border: 1px solid var(--color21);
  border-radius: 15px;
  padding: 9px;
  text-align: left;
  width: ${width || '200px'};
  height: ${height || '40px'};
  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
      color: var(--color20);
    }
  }

  &::after {
    content: '▾';
    float: right;
    color: var(--color20);
  }
  `,
);

const StyledListbox = props => styled('ul')(
    ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 5px 0;
  width: ${props?.widthListOption || 'auto'};
  min-width: ${props?.widthlist || '300px'};
  max-width: ${props?.maxWidthList || '100%'};
  background: ${theme.palette.mode === 'dark' ? grey[900] : 'var(--color3)'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  max-height: ${props?.heightlist || '200px'};
  
  &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 15px;
    }
    
  &::-webkit-scrollbar
    {
      width: 12px;
    }
    
  &::-webkit-scrollbar-thumb
    {
      border-radius: 15px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: var(--color52);
    }
  `,
);
const StyledPopper = styled(PopperUnstyled)`
  z-index: 999999;
`;

export const CustomSelect = (props) => {
    const components = {
        Root: props?.error ? StyledButtonError(props?.width, props?.height) : StyledButton(props?.bg, props?.borderradius, props?.width, props?.height),
        Listbox: StyledListbox(props),
        Popper: StyledPopper,
        ...props.components,
    };

    return <SelectUnstyled {...props} components={components} />;
}