import React, {useState, useEffect, Fragment} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles, withStyles} from "@mui/styles";
import MuiListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {connect} from "react-redux";
import {selectGame, selectItem} from "actions/common";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../assets/color.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './listItem.scss'
import {CardMedia} from "@mui/material";
const useStyles = makeStyles({
    title2: {
        height: '20px',
        margin: "0 0 40px 16px !important",
        backgroundColor: 'transparent',
        padding: '0 16px !important',
        cursor: 'pointer'
    },
    closeTitle: {
        marginBottom: "2px",
        backgroundColor: 'var(--neutral-1000-day)',
        borderTopRightRadius: "30px",
        color: 'var(--neutral-1000-day)'
    },
    root: {
        padding: "40px",
        height: "500px",
    },
    paging: {
        "& .Mui-selected": {
            color: "var(--color2)!important",
            backgroundColor: "var(--color2)!important",
            minWidth: '1px',
            fontSize: '1px',
            height: '8px'
        },
        "& .MuiPaginationItem-root": {
            color: "var(--color1)",
            backgroundColor: "var(--color1)",
            minWidth: '1px',
            fontSize: '1px',
            height: '8px',
            margin: '10px'
        }
    },
    tableContainer: {
        "&.Mui-selected": {
            backgroundColor: "var(--color77)!important"
        },
        "&.MuiTableRow-hover": {
            "&:hover": {
                backgroundColor: "var(--color78)!important"
            }
        }
    },
    dialogCustomizedWidth: {
        maxWidth: "750px!important",
        overflow: "hidden",
        borderRadius: '20px!important',
        "& .MuiInputBase-root": {
            borderRadius: '40px',
            height: '40px',
            "& svg": {
                color: "var(--color18)",
            },
            "& fieldset": {
                borderColor: 'var(--color18)'
            }
        }
    },
    titleDialog: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    button: {
        borderRadius: '40px!important',
        backgroundColor: 'var(--color18)!important',
        height: "40px"
    },
    scrollBar: {
        // "&::-webkit-scrollbar-track": {
        //     borderRadius: "10px",
        //     width: "5px",
        //     backgroundColor: "var(--color57)",
        // },
        // "&::-webkit-scrollbar": {
        //     borderRadius: "10px",
        //     width: "5px",
        //     backgroundColor: "var(--color58)",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     borderRadius: "10px",
        //     width: "5px",
        //     backgroundColor: "var(--color58)"
        // },
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 50px',
        backgroundAttachment: 'local, local, scroll, scroll',
        position: "relative" ,
        '@media screen and (min-width: 1024px) and (max-height: 900px)': {
            maxHeight: '90vh',
        },
    },
    menuItem: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: '16px !important',
        letterSpacing: '0.03em !important',
        color: 'var(--color7) !important',
    },
    menuItemHeading: {
        color: 'var(--color72) !important',
        fontSize: '12px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.05em !important',
        lineHeight: '14px !important',
    },
    menuItemDivide: {
        borderColor: 'var(--color15)!important',
    },
    gridMenuItem: {
        "&.MuiButtonBase-root": {
            color: 'var(--color84)',
            backgroundColor: 'var(--color102)',
            borderRadius: '3px',
            width:'100%',
            height:'30px',
            fontWeight:'600',
            fontSize:'12px',
            "&:hover": {
                backgroundColor: "#BDBBAD",
                color: "#1C1B1B",
            }
        }
    },
    gridMenuItemSelected: {
        "&.MuiButtonBase-root": {
            color: 'var(--color105)',
            backgroundColor: 'var(--neutral-1000-day)',
            border: '1px solid var(--color105)',
            borderRadius: '3px',
            width:'100%',
            height:'30px',
            fontWeight:'700',
            fontSize:'12px',
            "&:hover": {
                backgroundColor: "#BDBBAD",
                color: "#1C1B1B",
            }
        }
    },
});

const ListItem = withStyles({
    root: {
        "&.Mui-selected": {
            width: "100%"
        },
        "&$selected": {},
        "&$selected:hover": {
            backgroundColor: "var(--color79)",
        },
    }
})(MuiListItem);

const ListItemsNew = ({   user,
                           selectItem,
                           selectedItem,
                           toggleDrawer,
                           selectedGame,
                           listLeftMenu,
                           selectGame,
                           hideGame
                       }) => {
    const {t} = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [menuList, setMenuList] = useState([]);
    const [width,setWidth] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState({
        BETTING: true,
        REPORT: true,
        ACCOUNT: true,
        VIEW_LOGS: true,
        MAIN: true,
        PROFILE: true
    });
    const location = useLocation();
    const arrPath = location?.pathname?.split('/')?.[2];
    console.log('arrPath=====', arrPath)
    useEffect(() => {
        setSelectedIndex(selectedItem);
    }, [selectedItem]);

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);
    useEffect(() => {
        setMenuList(listLeftMenu);
        console.log('listLeftMenu======', listLeftMenu)
    }, [listLeftMenu]);

    //handle search
    const handleListItemClick = (name) => {
        console.log('name=====', name)
        const newbac = menuList?.map( s => {
            if(name=== s?.menuCode) {
                if(s?.subMenu?.length > 0){
                    return {
                        ...s, subMenu: []
                    }
                } else {
                    return {
                        ...s, subMenu: listLeftMenu?.find(ll => ll?.menuCode === name)?.subMenu
                    }
                }
            } else {
                return s
            }
        })
        console.log('newbac====', newbac)
        setMenuList(newbac);
    };
    const handleChooseGame = (value) => {
        if (value === selectedGame) {
            return;
        }
        selectGame(value);
        let url = '/toto/betting/simple-bet'.replace(selectedGame, value);
        history.push(url);
    }
    const handleGotoResult = () => {
        let url = `/${selectedGame}/main/result`;
        history.push(url);
    }
    const handleGotoDownlineCredit = () => {
        let url = `/main/profile/current-downline-credit`;
        history.push(url);
    }
    console.log('menuList=====', menuList);
    const handleRenderMenu = () => {
        if(arrPath === 'profile') {
            const newArr = menuList?.filter(s => s?.menuCode === 'MAIN')
            console.log('menuList?.filter(s => s?.menuCode =====', menuList?.filter(s => s?.menuCode === 'MAIN'))
            return  [
                {description: "Profile",
                    menuCode: "PROFILE",
                    menuLink: "/profile",
                    menuName: "Profile",
                    subMenu: [
                        {
                            "subMenuCode": "MY_PROFILE",
                            "subMenuName": "My Profile",
                            "subMenuLink": "/main/profile",
                            "description": "profile"
                        },
                        {
                            "subMenuCode": "SETTING_PROFILE",
                            "subMenuName": "Setting",
                            "subMenuLink": "/main/profile/setting",
                            "description": "Setting"
                        },
                        {
                            "subMenuCode": "CHANGE_PASSWORD",
                            "subMenuName": "Password",
                            "subMenuLink": "/main/profile/password",
                            "description": "password"
                        },
                        {
                            "subMenuCode": "SETTLEMENT",
                            "subMenuName": "Settlement",
                            "subMenuLink": "/main/profile/current-downline-credit",
                            "description": "Settlement"
                        }
                    ]
                }
               ]


        } else {
            return menuList?.filter(s => s?.menuCode !== 'MAIN')
        }
    }
    return (
        <div>
            {toggleDrawer && (
                <div>
                    <Fragment>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={`${classes.scrollBar} menu-list`}

                            >
                                {menuList?.length> 0 && handleRenderMenu()?.map((item) => (
                                    <>

                                        <ListItem
                                            className={'menu-item'}
                                            button
                                            onClick={() => handleListItemClick(item?.menuCode)}
                                        >
                                            <Typography variant="subtitle1" className={'text-menu'}>{t(item?.menuName)}</Typography>
                                            {isOpen?.[item?.menuCode] ? <ExpandMoreIcon sx={{color: 'var(--neutral-1000-day)'}}/> : <ExpandLessIcon/>}

                                        </ListItem>
                                        {
                                            isOpen?.[item?.menuCode] && item?.subMenu?.map((sub) =>
                                                sub.subMenuCode === 'TICKET_FIGHTING' && user?.level === 'AG' ? null :
                                                    (
                                                        <ListItem
                                                            button
                                                            component={Link}
                                                            to={arrPath === 'profile' ? `${sub?.subMenuLink}` : `/${selectedGame}${sub?.subMenuLink}` }
                                                            selected={selectedItem === sub?.subMenuCode}
                                                            sx={{padding: '3px', paddingLeft: '15px'}}
                                                            className={'sub-menu-row'}
                                                        >
                                                            <ListItemText primary={
                                                                <div className={'sub-menu-item'}>
                                                                    <CardMedia
                                                                        component={"img"}
                                                                        src={require(`../../../assets/${sub?.subMenuCode}.png`)}
                                                                        sx={{width: 21, height: 16, paddingRight: '5px'}}
                                                                    />
                                                                    <Typography ml={2} variant='subtitle1' className={'text-sub-menu'} sx={{fontSize: '13px'}}>
                                                                        {t(sub?.subMenuName)}

                                                                    </Typography>
                                                                </div>
                                                            }/>


                                                        </ListItem>
                                                    )
                                            )
                                        }
                                        {/*{*/}
                                        {/*    isOpen?.[item?.menuCode] && item?.subMenu?.map((sub) => (*/}
                                        {/*        <ListItem*/}
                                        {/*            button*/}
                                        {/*            component={Link}*/}
                                        {/*            to={`/${selectedGame}${sub?.subMenuLink}`}*/}
                                        {/*            selected={location?.pathname === `/${selectedGame}${sub?.subMenuLink}`}*/}
                                        {/*            onClick={() => handleListItemClick( sub?.subMenuCode)}*/}
                                        {/*            sx={{padding: '3px', paddingLeft: '15px'}}*/}
                                        {/*        >*/}
                                        {/*            <ListItemText primary={*/}
                                        {/*                <Typography ml={2} variant='subtitle1' sx={{fontSize: '13px'}}>*/}
                                        {/*                    {t(sub?.subMenuName)}*/}
                                        {/*                </Typography>}/>*/}
                                        {/*        </ListItem>*/}
                                        {/*    ))*/}
                                        {/*}*/}
                                    </>
                                ))}

                            </Grid>
                        </Grid>

                    </Fragment>
                </div>
            )}
        </div>
    )
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    selectedItem: state.common.menuItemSelected,
    selectedGame: state.common.selectedGame,
    listLeftMenu: state.auth.listLeftMenu
});

export default connect(mapStateToProps, {
    selectItem,
    selectGame
})(ListItemsNew);