import {BETTING_HISTORY_TOTO, CANCEL_BETTING_TOTO} from "../../../actions/type";

const initialState = {
    listBettingTotoHistory: [],
    isUpdateToto: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case BETTING_HISTORY_TOTO:
            return {
                ...state,
                listBettingTotoHistory: payload
            }
        case CANCEL_BETTING_TOTO:
            return {
                ...state,
                isUpdateToto: !state.isUpdateToto
            }
        default:
            return state;
    }
}
