import {
    AUTH_ERROR,
    CHANGE_LOGOUT_STATUS,
    CHANGE_PASSWORD, DELETE_API_KEY,
    EDIT_ALERT,
    ERROR_ALERT,
    GENRATE_API_KEY,
    GET_API_KEY, GET_API_KEY_BY_ID,
    GET_LEFT_MENU,
    GET_TNC_CONTENT,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    TNC_AGREED,
    UPDATE_PHONE_PROFILE,
    USER_LOADED
} from "./type";
import {getLocalToken, setLocalToken} from "../config/globalStatement";
import setAuthToken from "../utils/setAuthToken";
import axios from "axios";
import {REACT_API_LOCATION} from "../constants/constants";
export const login = (username, password) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": ""
        },
        withCredentials: false,
    };
    const body = JSON.stringify({ "userName": username, "password": password });
    try {
        const res = await axios.post(`${REACT_API_LOCATION}/agent-service/auth/login`,
            body,
            config
        );
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res?.data?.data,
        });
        loadUser()
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.data.token}`;
        setLocalToken(res?.data?.data?.token)
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error?.response?.data?.msg
        });
    }
};
export const loadUser = () => async (dispatch) =>{
    if(localStorage.token) {
        setAuthToken()
    }
    const token = getLocalToken();
    try {
        let userData = {};
        if(token !== null) {
            // call API get profile here
            const res1 = await axios.get(`${REACT_API_LOCATION}/agent-service/profile`);
            const dataRes = res1?.data?.data;
            const isSubaccount = dataRes?.isSubAccount;
            userData = {
                        id: dataRes?.userId,
                        userId: dataRes?.userId,
                        username: dataRes?.userName,
                        phone1: dataRes?.phone1,
                        phone2: dataRes?.phone2,
                        phone3: dataRes?.phone3,
                        nickName: dataRes?.nickName,
                        uploadDataCode: dataRes?.uploadDataCode,
                        agentCreditLimit: dataRes?.agentCreditLimit,
                        creditUsed: dataRes?.creditBalance,
                        creditAvaiable: dataRes?.creditAvailable,
                        autoResetBalance: dataRes?.permission?.autoResetBalance, // get from permission
                        status: dataRes?.status,
                        createAgent: dataRes?.permission?.createAgent, // get from permission
                        deleteBet: dataRes?.permission?.deleteBet, // get from permission_ still not handle in UI
                        createPayment: isSubaccount ? dataRes?.permission?.accountSettings?.payments : true, // get from permission
                        statement: isSubaccount ? dataRes?.permission?.accountSettings?.statement : true, // get from permission
                        fakePass:  dataRes?.permission?.fakePass || true, // get from permission
                        androidApp:  dataRes?.permission?.androidApp, // get from permission still not handle yet, maybe we dont have android app
                        saveBet: dataRes?.permission?.saveBets, // get from permission
                        loginHistory: dataRes?.permission?.loginHistory, // get from permission
                        systemHistory: dataRes?.permission?.systemHistory,
                        placeBets: dataRes?.permission?.placeBets, // get from permission
                        remarks: dataRes?.remarks,
                        permission: dataRes?.permission, // get from permission
                        downline:  isSubaccount ? dataRes?.permission?.accountSettings?.downLines: true, // get from permission
                        onlineList:  isSubaccount ? dataRes?.permission?.accountSettings?.onlineList : true, // get from permission
                        level: dataRes?.level,
                        companyReport:  dataRes?.permission?.companyReport, // get from permission
                        fullReport:  isSubaccount ? dataRes?.permission?.reports?.fullReport : true,
                        placeoutReport:  isSubaccount ? dataRes?.permission?.reports?.placeoutReport: true,
                        summaryReport:  isSubaccount ? dataRes?.permission?.reports?.summaryReport : true ,
                        betDetail:  isSubaccount ? dataRes?.permission?.reports?.betDetail : true,
                        strikeReport:  isSubaccount ? dataRes?.permission?.reports?.strikeReport : true,
                        saveDetail:  isSubaccount ? dataRes?.permission?.reports?.saveDetail : true,
                        fixedDetail:  isSubaccount ? dataRes?.permission?.reports?.fixedDetail : true,
                        commissions: dataRes?.commissions,
                        tncAgreed: dataRes?.tncAgreed
            }
        }
        dispatch({
            type: USER_LOADED,
            payload: userData
        })

    } catch (error) {
        dispatch({type: AUTH_ERROR})
    }
}
export const keepAlive = () => async (dispatch) =>{
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: false,
    };
    try {
       await axios.post(`${REACT_API_LOCATION}/agent-service/auth/keepAlive`,'', config);
    } catch (error) {
        dispatch({type: AUTH_ERROR})
    }
}

export const changeLogoutStatus = () => (dispatch) => {
    dispatch({ type: CHANGE_LOGOUT_STATUS });
};
export const logout = () => (dispatch) => {
    dispatch({ type: LOGOUT });
};

    export const editPhoneProfile = (data) => async (dispatch) =>{
        try {
            const body = JSON.stringify(data);
            const config = {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: false,
            };
            await axios.put(`${REACT_API_LOCATION}/agent-service/profile`,body, config);
            dispatch({
                type: UPDATE_PHONE_PROFILE
            })
            dispatch({
                type: EDIT_ALERT,
                payload: "Edit Success",
            })
        } catch (error) {
            dispatch({ type: ERROR_ALERT, payload: error.response.data?.msg });
        }
    }
export const changePassword = (data) => async (dispatch) =>{
    try {
        const body = JSON.stringify(data);
        const config = {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: false,
        };
        await axios.post(`${REACT_API_LOCATION}/agent-service/profile/changePassword`,body, config);
        dispatch({
            type: CHANGE_PASSWORD
        })
        dispatch({
            type: EDIT_ALERT,
            payload: "Edit Success",
        })
    } catch (error) {
        dispatch({ type: ERROR_ALERT, payload: error.response.data?.msg });
    }
}

export const getListMenu = (gameCode) => async (dispatch) =>{
    try {
        // Call API
        const res = await axios.get(`${REACT_API_LOCATION}/agent-service/leftMenu?gameCode=${gameCode === 'toto' ? 'TOTO': 'SG4D'}`);
        dispatch({
            type: GET_LEFT_MENU,
            payload: res?.data?.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const getTnc = () => async (dispatch) =>{
    try {
        // Call API
        const res = await axios.get(`${REACT_API_LOCATION}/agent-service/terms-conditions/standard`);
        dispatch({
            type: GET_TNC_CONTENT,
            payload: res?.data?.data?.contents
        })
    } catch (error) {
        console.log(error)
    }
}

export const setAgreeTnc = () => async (dispatch) =>{
    try {
        const data = {
            "tncAgreed": true
        }
        const res = await axios.patch(`${REACT_API_LOCATION}/agent-service/profile/update-tnc-agreed`, data)
        dispatch({
            type: TNC_AGREED,
            payload: res?.data?.data
        })
    } catch (error) {
        console.log(error)
    }
}
export const getApiKey = () => async (dispatch) =>{
    try {
        const res = await axios.get(`${REACT_API_LOCATION}/agent-service/apikey`);
        dispatch({
            type: GET_API_KEY,
            payload: res?.data?.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const GenerateApiKey = (name, date, apiKeyScopes) => async (dispatch) =>{
    try {
        const dateString = date?.$d || date;
        const dateObject = new Date(dateString);

        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');

        const formattedDate = `${day}/${month}/${year}`;
        const data = {
            "name": name,
            "validThru": formattedDate,
            "apiKeyScopes": apiKeyScopes
        }
        const body = JSON.stringify(data);
        const config = {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: false,
        };
        await axios.post(`${REACT_API_LOCATION}/agent-service/apikey`,body, config);
        dispatch({
            type: GENRATE_API_KEY
        })
        dispatch({
            type: EDIT_ALERT,
            payload: "Generate API Ke Success",
        })
    } catch (error) {
        dispatch({ type: ERROR_ALERT, payload: error.response.data?.msg });
    }
}

export const getApiKeyById = (id) => async (dispatch) =>{
    try {
        const res = await axios.get(`${REACT_API_LOCATION}/agent-service/apikey/${id}`);
        dispatch({
            type: GET_API_KEY_BY_ID,
            payload: res?.data?.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const deleteApiKey = (id) => async (dispatch) =>{
    try {
        const config = {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: false,
        };
        await axios.delete(`${REACT_API_LOCATION}/agent-service/apikey/${id}`, config)
        dispatch({
            type: DELETE_API_KEY
        })
    } catch (error) {
        console.log(error)
    }
}