import {
    GET_LIST_ONLINE_LIST, LOGOUT_BY_USER_ID
} from "../../../actions/type";

const initialState = {
    listOnline: [],
    isUpdate: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_ONLINE_LIST:
            return {
                ...state,
                listOnline: payload
            }
        case LOGOUT_BY_USER_ID:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        default:
            return state;
    }
}
