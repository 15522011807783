import React, {useEffect} from "react";
import Snackbar from "@mui/material/Snackbar";
import { connect } from "react-redux";
import { closeAlert } from "actions/alert";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {useHistory} from "react-router-dom";
import "../../assets/color.css";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    root: {
        "& .MuiSnackbarContent-root": {
            display: "block",
        },
        "& .MuiAlert-root" : {
            backgroundColor: "var(--color14)",
            borderRadius: "6px",
            minWidth: "400px",
            "& .MuiAlert-message" : {
                letterSpacing: "0.05em",
                fontSize: '14px',
                fontWeight: '400',
            }
        }
    },
});

function AlertSuccess({ closeAlert, msg, status }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(status);
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            setOpen(false);
            open && closeAlert();
        })
    },[history])

    const handleClose = (event, reason) => {
        // if (reason === "clickaway") {
        //     return;
        // }
        // setOpen(false);
        // closeAlert();
    };

    return (
        <div>
            <Snackbar
                severity="success"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                className={classes.root}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
            >
                <Alert  severity={'success'} sx={{ width: "100%" }}>
                    <Typography variant="subtitle2">{(msg)}</Typography>
                </Alert>
            </Snackbar>
        </div>
    );
}

const mapStateToProps = (state) => ({
    status: state.alert.status,
    msg: state.alert.msg,
});

export default connect(mapStateToProps, { closeAlert })(AlertSuccess)