import {connect} from "react-redux";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {selectItem} from "../../../actions/common";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {GetDetectBrowserInfo, LIST_MENU_CODE} from "../../../constants/constants";
import Grid from "@mui/material/Grid";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import "../../../assets/color.css";
import Box from "@mui/material/Box";
import {ItemPanelPaper} from "../../../components/PanalPaper/ItemPanelPaper";
import {deleteApiKey, GenerateApiKey, getApiKey, getApiKeyById} from "../../../actions/auth";
import ModalAPIKeyCreate from "./Dialog/ModalAPIKeyCreate";

const useStyles = makeStyles({
    textInfo: {
        padding: '0 0.6rem',
        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: '1.3 !important',
        letterSpacing: '0.05em !important',
        color: 'var(--color11) !important',

        // Text truncate
        // display: '-webkit-box',
        // '-webkit-box-orient': 'vertical',
        // '-webkit-line-clamp': 1,
        // overflow: 'hidden',

        '&.value': {
            color: 'var(--color7) !important',
            padding: '0 1.5rem',
        },

        '&.is-tag': {
            '--primary-clr': 'var(--color2)',
            border: '1px solid var(--primary-clr)',
            borderRadius: '20px',
            padding: '0.2rem 1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'var(--primary-clr) !important',
        },
    },
    headerText: {
        fontWeight: '700',
        fontSize: '16px'
    },
    formInfoWrapper: {
        paddingBottom: '0.6rem',
        borderBottom: '1px solid var(--color15)',
        display: 'flex',
        alignItems: 'center',

        '&.edit-icon': { justifyContent: 'flex-end' },
    },
    editIcon: {
        '--size': '1rem',
        width: 'var(--size) !important',
        height: 'var(--size) !important',
        cursor: 'pointer !important',
    },
    root: {
        width: "53px !important",
        height: "27px !important",
        padding: "0px !important"
    },
    switchBase: {
        color: "var(--color55) !important",
        padding: "1px !important",
        "&$checked": {
            "& + $track": {
                backgroundColor: "var(--color54) !important"
            }
        }
    },
    thumb: {
        color: "white!important",
        width: "25px !important",
        height: "25px !important",
        margin: "0px !important"
    },
    track: {
        borderRadius: "20px!important",
        backgroundColor: "var(--color55)",
        opacity: "1 !important",
        "&:after, &:before": {
            color: "white!important",
            fontSize: "11px!important",
            position: "absolute!important",
            top: "6px!important"
        },
        "&:after": {
            content: props => props.after,
            left: "8px!important"
        },
        "&:before": {
            content: props => props.before,
            right: "7px!important"
        }
    },
    checked: {
        color: "var(--color56) !important",
        transform: "translateX(26px) !important"
    }
});

const GAME_MAPPING = {
    '4d': 'SG4D',
    'toto': 'TOTO'
}

const Setting = ({selectItem, userSetting, selectedGame, user, getApiKey, GenerateApiKey, getApiKeyById, listApiKeys, isUpdateApiKey, deleteApiKey}) => {
    const {t} = useTranslation();
    const {isDesktop, isLaptop, isTablet} = GetDetectBrowserInfo();
    const optionContent = {after: `'${t("Yes")}'`, before: `'${t("No")}'`};
    const classes = useStyles(optionContent);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        selectItem(LIST_MENU_CODE?.SETTING_PROFILE)
    }, []);
    useEffect(() => {
        getApiKey();
    }, [isUpdateApiKey]);

    const commission = useMemo(() => {
        return user?.commissions?.find(s => s?.gameCode === GAME_MAPPING[selectedGame])?.commission;
    }, []);

    const handleOpenCreate = () => {
        setOpenDialog(true);
    }
    const handleDeleteAPI = (id) => {
        deleteApiKey(id)
    }
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box px={(isLaptop || isDesktop) ? 2 : 0}>
                        <Grid container
                              spacing={1}
                              justifyContent="space-between"
                              alignItems="center"
                        >
                            <Grid item xs={12}>
                                <ItemPanelPaper>
                                    <Grid container p={(isLaptop || isDesktop || isTablet) ? 4 : 2} spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} p={1}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5} className={`${classes.formInfoWrapper} label`}>
                                                            <Tooltip title={t('Tkt Rate()')} arrow>
                                                                <Typography className={classes.textInfo}>{t('Tkt Rate()')}</Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={7} className={classes.formInfoWrapper}>
                                                            <Tooltip title={`${t("Big")}: ${userSetting?.tktRate?.big} ${t("Small")}: ${userSetting?.tktRate?.small}`} arrow>
                                                                <Typography className={`${classes.textInfo} value`}>
                                                                    {`${t("Big")}: ${userSetting?.tktRate?.big} ${t("Small")}: ${userSetting?.tktRate?.small}`}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5} className={`${classes.formInfoWrapper} label`}>
                                                            <Tooltip title={t(`${GAME_MAPPING[selectedGame]} commission`)} arrow>
                                                                <Typography className={classes.textInfo}>{`${t('Comm')} ${GAME_MAPPING[selectedGame]}`}</Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={7} className={classes.formInfoWrapper}>
                                                            <Tooltip title={userSetting?.firstPrize} arrow>
                                                                <Typography className={`${classes.textInfo} value`}>
                                                                    {commission} %
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5} className={`${classes.formInfoWrapper} label`}>
                                                            <Tooltip title={t(`${GAME_MAPPING[selectedGame]} commission`)} arrow>
                                                                <Typography className={classes.textInfo}>{'Account ID'}</Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={7} className={classes.formInfoWrapper}>
                                                            <Tooltip title={userSetting?.firstPrize} arrow>
                                                                <Typography className={`${classes.textInfo} value`}>
                                                                    {user?.userId}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={5} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                                                    <Tooltip title={t(`${GAME_MAPPING[selectedGame]} commission`)} arrow>
                                                        <Typography className={classes.headerText}>{t('API Key List')}</Typography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Grid item xs={'auto'} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{width: '100px', background: '#0277e4'}}
                                                            onClick={() => handleOpenCreate()}
                                                        >
                                                            {t("Generate")}
                                                        </Button>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.stickyTh}>{t("Name")}</TableCell>
                                                            <TableCell className={classes.styleTh}>{t("API Key")}</TableCell>
                                                            <TableCell className={classes.styleTh} align="center" >{t("Create at")}</TableCell>
                                                            <TableCell className={classes.styleTh} align="center" >{t("Valid Date")}</TableCell>
                                                            <TableCell className={classes.styleTh} align="center" >{t("Scope")}</TableCell>
                                                            <TableCell className={classes.styleTh} align="center" >{t("Action")}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {listApiKeys?.map((item, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell>
                                                                    {item?.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.apiKey}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {item.createdAt}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {item.validThru}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {item.apiKeyScopes?.toString()}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                            width: (isDesktop || isLaptop || isTablet) ? '200px !important' : '100% !important',
                                                                            background: '#feaeae',
                                                                            color: 'white'
                                                                    }}
                                                                        onClick={() => handleDeleteAPI(item?.id)}
                                                                    >
                                                                        {t("Delete")}
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    {listApiKeys?.length === 0 && (
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="center" colSpan={20} sx={{ py: 3 }}>
                                                                    <Typography variant="h6"  sx={{
                                                                        textAlign: 'center',
                                                                    }}>
                                                                        Record not found
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )}
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </ItemPanelPaper>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <ModalAPIKeyCreate
                open={openDialog}
                onClose={()=> setOpenDialog(false)}
                GenerateApiKey={GenerateApiKey}
            />
        </Fragment>
    );
};
const mapStateToProps = (state) => ({
    userSetting: state.auth.userSetting,
    selectedGame: state.common.selectedGame,
    user: state.auth.user,
    listApiKeys: state.auth.listApiKeys,
    isUpdateApiKey: state.auth.isUpdateApiKey
});
export default connect(mapStateToProps, {selectItem, getApiKey, GenerateApiKey, getApiKeyById, deleteApiKey})(Setting);