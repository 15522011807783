
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import {CardContent, Divider} from "@mui/material";
import {makeStyles, withStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {selectItem} from "../../../../actions/common";
import {formatDate, genInnerLink} from "../../../../utils/common";
import LinkText from "../../../../components/linkText/LinkText";
import {clickDeletedLink} from "../../../../actions/socket";
import "../../../../assets/color.css";
const StyledMenuItem = withStyles((theme) => ({
    root: {
        ...theme.typography.body2,
        "& .MuiListItemText-primary": {
            padding: theme.spacing(1),
            textAlign: 'center',
            border: 'var(--color1)'
        },
    },
}))(MenuItem);

const useStyles = makeStyles(() => ({
    scrollBar: {
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            width: "10px",
            backgroundColor: "var(--color57)",
        },
        "&::-webkit-scrollbar": {
            borderRadius: "10px",
            width: "10px",
            backgroundColor: "var(--color58)",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            width: "10px",
            backgroundColor: "var(--color58)"
        },
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.03) 100%) center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 50px',
        backgroundAttachment: 'local, local, scroll, scroll',
        paddingRight: '10px',

        '@media screen and (min-width: 1024px) and (max-height: 900px)': {
            maxHeight: '60vh',
        },
    },
}))
const NotificationLayout = ({selectItem, latestNotifications, clickDeletedLink}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [renderData, setRenderData] = useState([]);

    useEffect(() => {
        setRenderData(latestNotifications?.length > 0 ? latestNotifications : []);
    },[latestNotifications])

    const handleSystemAndSelected = () => {
        selectItem("notification-center");
    };


    return (
        <Grid>
            <Grid>
                <ListItemText
                    sx={{textAlign: "left !important"}}
                    primary={t("Notifications")}
                    primaryTypographyProps={{
                        color: "var(--color72)",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        fontSize: '12px',
                        letterSpacing: '0.05em',
                        padding: '15px 15px 0 15px'
                    }}/>
            </Grid>
            <Divider/>
            <Grid sx={{maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden', marginRight: '10px'}} className={classes.scrollBar}>
                {
                    renderData && renderData?.length > 0 ? (
                        renderData?.map((item, index) =>(
                            <Grid key={index}>
                                <StyledMenuItem button sx={{padding: 'unset'}}>
                                    <CardContent sx={{borderLeft: !item.isRead ? '6px solid var(--color2)' : 'unset', width: '100%', backgroundColor: !item.isRead ? 'var(--color10)' : ''}}>
                                        <Grid item container>
                                            <Grid item xs={6}>
                                                <Typography sx={{
                                                    fontSize: '12px',
                                                    lineHeight: '15px',
                                                    color: 'var(--color2)',
                                                    letterSpacing: '0.05em',
                                                    textTransform: 'capitalize'
                                                }}>
                                                    {t(item.notificationType)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{
                                                    textAlign: 'right',
                                                    fontSize: '12px',
                                                    lineHeight: '15px',
                                                    color: 'var(--color72)',
                                                    letterSpacing: '0.05em',
                                                }}>
                                                    {formatDate(item.notificationTime)}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                color: 'var(--color7)',
                                                letterSpacing: '0.05em',
                                                marginTop: '0.6rem',
                                                whiteSpace: 'pre-wrap'
                                            }}
                                        >
                                            <Trans t={t} i18nKey={item?.message}
                                                   values={{ environment: item?.values?.environment, itemName: item?.values?.itemName, networkName: item?.values?.networkName, companyName: item?.values?.companyName, billingId: item?.values?.billingId, orderNo: item?.values?.orderNo, amount: item?.values?.amount, dueDate: item?.values?.dueDate}}
                                                   components={{lnk: <LinkText href={genInnerLink(item)} item={item} clickDeletedLink={clickDeletedLink} />}}/>
                                        </Typography>
                                    </CardContent>
                                </StyledMenuItem>
                                {
                                    index !== renderData?.length - 1 && <Grid sx={{borderLeft: !item.isRead ? '6px solid var(--color2)' : 'unset'}}>
                                        <Divider/>
                                    </Grid>
                                }
                            </Grid>
                        ))) : (
                        <StyledMenuItem textAlign={"center"} sx={{padding: 'unset'}}>
                            <CardContent sx={{width: '100%'}}
                                         justifyContent="center"
                                         alignItems="center">
                                <Typography
                                    textAlign={"center"}
                                    style={{
                                        fontSize: '14px',
                                        color: 'var(--color7)',
                                        letterSpacing: '0.05em',
                                        marginTop: '0.6rem',
                                        whiteSpace: 'pre-wrap',
                                        width: '100%'
                                    }}
                                >
                                    {"No record found"}
                                </Typography>
                            </CardContent>
                        </StyledMenuItem>
                    )}
            </Grid>
            <Grid>
                <Divider/>
            </Grid>
            <Grid>
                <StyledMenuItem
                    component={Link}
                    to="/notification-center"
                    onClick={handleSystemAndSelected}
                >
                    <ListItemText
                        component={Link}
                        to="/notification-center"
                        onClick={handleSystemAndSelected}
                        sx={{textAlign: "left !important", cursor: "pointer"}}
                        primary={t("View all notifications")}
                        primaryTypographyProps={{
                            color: "var(--color2)",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            fontSize: '14px',
                            letterSpacing: '0.05em',
                            padding: '0 15px 15px 15px',
                        }}/>
                </StyledMenuItem>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    user: state.auth.user,
    latestNotifications: state.notification.latestNotifications,
});

export default connect(mapStateToProps, {selectItem, clickDeletedLink})(NotificationLayout);