import React from 'react';
import {CircularProgress} from "@mui/material";

const Loading = () => {
    return(
            <div
                style={{
                    display: 'grid',
                    placeItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress disableShrink />
            </div>
        )
};

export default Loading;