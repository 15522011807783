import React, {useEffect} from "react";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {useHistory} from "react-router-dom";
import "../../assets/color.css";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    root: {
        "& .MuiSnackbarContent-root": {
            display: "block",
        },
        "& .MuiAlert-root" : {
            backgroundColor: "var(--color47)",
            borderRadius: "6px",
            minWidth: "400px",
            "& .MuiAlert-message" : {
                letterSpacing: "0.05em",
                fontSize: '14px',
                fontWeight: '400',
            }
        }
    },
});

export const ToastError = ({ msg, closeToast}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const history = useHistory();

    useEffect(() => {
        return history.listen(() => {
            setOpen(false);
        })
    },[history])

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        closeToast();
    };

    return (
        <div>
            <Snackbar
                severity="error"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                className={classes.root}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    <Typography variant="subtitle2">{msg}</Typography>
                </Alert>
            </Snackbar>
        </div>
    );
}