import { makeStyles } from "@mui/styles";
import "../../assets/color.css";
export const dialogStyles = makeStyles({
    dialogContainer: {
        '& .MuiDialog-paper': {
            padding: '70px 60px 60px',
            boxShadow: '0px 4px 20px 0px rgba(7, 6, 18, 0.10)',
            borderRadius: '12px',
            overflowY: 'unset',
            width: '700px',
            maxWidth: 'calc(100vw - 2rem)',
            height: 'auto',
            maxHeight: 'calc(100vh - 2rem)',
            backgroundColor: 'var(--neutral-200-day)',
            margin: 0,

            // Mobile devices
            '@media screen and (max-width: 767px)': {
                padding: '1.5rem',
            },
        },
    },

    dialogLabel: {
        padding: '0 !important',
        color: 'var(--color7) !important',
        fontSize: '20px !important',
        fontWeight: '500 !important',
        lineHeight: '23px !important',
        letterSpacing: '0.05em !important',
    },

    formContainer: {
        marginTop: '20px',
        padding: '30px 0 !important',
        '--border': '1px solid var(--color15)',
        borderTop: 'var(--border)',
        borderBottom: 'var(--border)',
        overflow: 'unset',
    },

    formLabelContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    formLabel: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: '1.8 !important',
        letterSpacing: '0.05em !important',
        color: 'var(--color11) !important',
    },
    formLabelError: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: '1.8 !important',
        letterSpacing: '0.05em !important',
        color: 'red !important',
    },

    formRequireMark: {
        color: 'var(--color44)',
        marginLeft: '0.5rem',
    },

    formInput: {
        borderRadius: '5px !important',
        height: '40px !important',
        padding: '0 16px !important',
        width: '100% !important',
        maxWidth: '100% !important',

        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: '1 !important',
        letterSpacing: '0.05em !important',
        color: 'var(--color7) !important',
        background: 'var(--neutral-00-day)',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: 'var(--neutral-00-day)'
        }
    },

    actionsContainer: {
        padding: '0 !important',
        marginTop: '40px',

        // Mobile devices
        '@media screen and (max-width: 767px)': {
            marginTop: '1.5rem',
        },
    },

    formSelectItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid var(--color15)',
        borderRadius: '15px',
        backgroundColor: 'var(--color3)',
        height: '60px',
        width: '100%',
        maxHeight: '100%',
        transition: 'all ease 325ms',
        willChange: 'border',
        cursor: 'pointer',
        boxSizing: 'border-box !important',

        '&.selected': { border: '4px solid var(--color18)' },
        '&:hover, &:active': { borderColor: 'var(--color18)' },
    },

    formSelectItemLabel: {
        color: 'var(--color7) !important',
        letterSpacing: '0.05em !important',
        fontWeight: '400 !important',
        fontSize: '14px !important',
        lineHeight: '1.8 !important',
    },

    formCheckboxLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: '14px !important',
            fontWeight: '400 !important',
            letterSpacing: '0.05em !important',
            lineHeight: '20px !important',
            marginLeft: '1rem !important',
            color: 'var(--color11) !important',
        },
    },

    formTagsInputContainer: {
        '& .rti--container': {
            '--height': '100px',
            height: 'auto',
            minHeight: 'var(--height)',
            maxHeight: 'calc(var(--height) * 2)',

            overflow: 'auto',
            border: '1px solid var(--color18)',
            borderRadius: '15px',
            padding: '1rem',
            alignItems: 'baseline',
            gap: '1rem !important',

            '&::-webkit-scrollbar-track, &::-webkit-scrollbar, &::-webkit-scrollbar-thumb': {
                '--size': '0.4rem',
                borderRadius: "1rem",
                height: 'var(--size)',
                width: 'var(--size)',
                backgroundColor: 'var(--color3)',
            },

            '&::-webkit-scrollbar-thumb': {
                width: 'unset',
                backgroundColor: 'var(--color45)',
            },
        },

        '& .rti--tag': {
            '--primary-clr': 'var(--color2)',
            border: 'solid 1px var(--primary-clr)',
            padding: '0.4rem 1.2rem',
            borderRadius: '20px',
            gap: '1rem',

            '& > span, & > button': {
                fontSize: '14px !important',
                fontWeight: '400 !important',
                lineHeight: '1.8 !important',
                letterSpacing: '0.05em !important',
                color: 'var(--primary-clr) !important',
            },
        },

        '& .rti--input': {
            width: 'auto! important',
            minWidth: '300px!important',
            fontSize: '14px !important',
            fontWeight: '400 !important',
            lineHeight: '1.8 !important',
            letterSpacing: '0.05em !important',
            color: 'var(--color7) !important',
        },

        '&.error': {
            '& .rti--container': { borderColor: 'var(--color44) !important' },

            '&.not-allowed': {
                cursor: 'not-allowed !important',

                '& .rti--container': { borderColor: 'var(--color46) !important' },
                '& .rti--input': {
                    cursor: 'not-allowed !important',
                    pointerEvents: 'none',
                },
            },
        },
    },
});