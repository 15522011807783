import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { styled } from '@mui/material/styles';

const blue = {
    100: 'var(--color27)',
    200: 'var(--color28)',
    400: 'var(--color29)',
    500: 'var(--color30)',
    600: 'var(--color31)',
    900: 'var(--color32)',
};

const grey = {
    100: 'var(--color33)',
    200: 'var(--color34)',
    300: 'var(--color34)',
    400: 'var(--color35)',
    500: 'var(--color36)',
    600: 'var(--color37)',
    700: 'var(--color38)',
    800: 'var(--color39)',
    900: 'var(--color40)',
};
export  const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: pointer;
    width: 100%;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      cursor: pointer;
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
    `,
);