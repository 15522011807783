import Link from "@mui/material/Link";
import {clickDeletedLink} from "../../actions/socket";
import {connect} from "react-redux";
import "../../assets/color.css";
const LinkText = ({href, children, item, clickDeletedLink, ...props}) => {
    const handleClick = () => {
        clickDeletedLink(item);
    }
    return (
        !item?.isDelete ? (
            <Link href={href || ''}>
                <a style={{
                    fontWeight: 'bold',
                    color: 'var(--color22)'
                }}>{children}</a>
            </Link>
        ) : (
            <Link onClick={handleClick}>
                <a style={{
                    fontWeight: 'bold',
                    color: 'var(--color22)',
                    cursor: 'pointer'
                }}>{children}</a>
            </Link>)
    )
};

export default connect(null, {clickDeletedLink})(LinkText);