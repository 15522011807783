import {
    CLOSE_NOTIFICATION, GET_LATEST_NOTIFICATION, GET_NOTIFICATION, GET_NOTIFICATION_CONFIG,
    GOT_NOTIFICATION, NOTIFICATION_UNREAD, READ_NOTIFICATION, SEND_NOTIFICATION, UPDATE_NOTIFICATION, GOT_MESSAGES
} from "actions/type";

const initialState = {
    gotNotification: false,
    messages: [],
    notifications: [],
    latestNotifications: null,
    totalPage: 0,
    unRead: false,
    notificationConfig: [],
    updateNotification: false,
    unSendMessages: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SEND_NOTIFICATION: {
            return {
                ...state,
                messages: state.messages.concat([payload]),
            }
        }
        case GOT_MESSAGES: {
            return {
                ...state,
                unSendMessages: state.unSendMessages.concat([payload]),
            }
        }
        case GOT_NOTIFICATION: {
            return {
                ...state,
                gotNotification: true,
            }
        }
        case CLOSE_NOTIFICATION: {
            return {
                ...state,
                messages: state.messages.filter(rs => rs.id !== payload),
                gotNotification: false,
            }
        }
        case GET_LATEST_NOTIFICATION: {
            return {
                ...state,
                latestNotifications: payload,
            }
        }
        case NOTIFICATION_UNREAD: {
            return {
                ...state,
                unRead: payload > 0
            }
        }
        case READ_NOTIFICATION: {
            return {
                ...state,
                unRead: false,
                gotNotification: false,
            }
        }
        case GET_NOTIFICATION: {
            return {
                ...state,
                notifications: payload.items,
                totalPage: payload.totalPage
            }
        }
        case GET_NOTIFICATION_CONFIG: {
            return {
                ...state,
                notificationConfig: payload
            }
        }
        case UPDATE_NOTIFICATION: {
            return {
                ...state,
                updateNotification: !state.updateNotification
            }
        }
        default:
            return state;
    }
}