import {GOT_NOTIFICATION, CLOSE_NOTIFICATION, ERROR_ALERT, SEND_NOTIFICATION, GOT_MESSAGES} from "./type";
import i18n from "../i18n";

export const gotNotification = () => async (dispatch) => {
    dispatch({
        type: GOT_NOTIFICATION,
    });
}

export const sendNotification = (msg) => async (dispatch) => {
    dispatch({
        type: SEND_NOTIFICATION,
        payload: msg,
    });
}

export const gotMessages = (msg) => async (dispatch) => {
    dispatch({
        type: GOT_MESSAGES,
        payload: msg,
    })
}

export const closeNotification = (id) => async (dispatch) => {
    dispatch({
        type: CLOSE_NOTIFICATION,
        payload: id,
    });
}

export const clickDeletedLink = (item) => async (dispatch) => {
    if (item?.isDelete) {
        dispatch({
            type: ERROR_ALERT,
            payload: {msg: i18n.t('Item has been deleted', {itemName: (item?.values?.itemName)})}
        })
    }
}